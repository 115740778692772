import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProducer } from "../context/ProducerContext";
import { Producer } from "../models/Producer";
import { toast } from "react-toastify";
import { useProduct } from "../context/ProductContext";
import { Product } from "../models/Product";
import ProductCard from "../components/ProductCard";

function ProducerPage() {
  const { id } = useParams<{ id: string }>();

  const [producer, setProducer] = React.useState<Producer | null>(null);
  const { getProducer } = useProducer();

  const [products, setProducts] = React.useState<Product[]>([]);
  const { getProductsForProducer } = useProduct();

  const navigate = useNavigate();
  useEffect(() => {
    async function fetchProducer() {
      try {
        const response = await getProducer(parseInt(id!));
        setProducer(response);
      } catch (e) {
        toast.error("Nie udało się pobrać producenta");
        navigate("/");
        console.error(e);
      }
    }

    async function fetchProducts() {
      try {
        const response = await getProductsForProducer(parseInt(id!));
        setProducts(response);
      } catch (e) {
        toast.error("Nie udało się pobrać produktów producenta");
        console.error(e);
      }
    }

    fetchProducer();
    fetchProducts();
  }, [id, getProducer]);

  if (!producer) return <div>Ładowanie...</div>;

  return (
    <div className="divide-y">
      <div className="p-2">
        <div className="flex items-center space-x-2 h-48 w-48 ">
          {producer.image && (
            <img
              src={producer.image}
              alt={producer.name}
              height={187.5}
              width={187.5}
              className="object-contain"
            />
          )}
          <h1 className="text-5xl font-bold">{producer.name}</h1>
        </div>
        <p className="text-lg mt-2">{producer.description}</p>
      </div>
      <div className="p-2">
        <h3 className="text-2xl font-bold">
          Wybrane produkty marki {producer.name}
        </h3>
        {products.length === 0 && (
          <div className="mt-5">
            <p className="text-gray-500">
              W tym momencie nie posiadamy produktów tego producenta
            </p>
          </div>
        )}
        <div className="flex items-center space-x-2">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              inDisplayMode={true}
              horizontal={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProducerPage;
