import React from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParameter } from "../context/ParameterContext";

interface ParameterFormData {
  name: string;
  description?: string;
}

interface ParameterFormProps {
  categoryId?: number;
  id?: number;
  name?: string;
  description?: string;
  inEditMode?: boolean;
}

function CategoryForm({
  categoryId,
  id,
  name = "",
  description = "",
  inEditMode = false,
}: ParameterFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ParameterFormData>({
    defaultValues: {
      name,
      description,
    },
  });

  const { createParameter, editParameter } = useParameter();
  const navigate = useNavigate();

  const onSubmit = async (data: ParameterFormData) => {
    const toastId = toast.loading(
      inEditMode ? "Edytowanie..." : "Dodawanie...",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      }
    );

    try {
      const formData = new FormData();
      formData.append("name", data.name);
      if (data.description) formData.append("description", data.description);

      if (inEditMode && id) {
        await editParameter(id, data.name, data.description);
        toast.update(toastId, {
          render: "Parametr edytowany!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        await createParameter(data.name, data.description, categoryId);
        toast.update(toastId, {
          render: "Parametr dodany!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }

      navigate("/admin/parameters");
    } catch (error: Error | any) {
      if (
        error?.message ===
        "Parameter already exists in this category or ancestors"
      ) {
        toast.update(toastId, {
          render:
            "Parametr o tej nazwie już istnieje w tej kategorii lub w kategorii nadrzędnej",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(toastId, {
          render: inEditMode
            ? "Nie udało się edytować parametru"
            : "Nie udało się dodać parametru",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col space-y-2 items-stretch w-full"
    >
      <Controller
        name="name"
        control={control}
        defaultValue={name}
        rules={{ required: "Nazwa jest wymagana" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Nazwa"
            error={!!errors.name}
            helperText={errors.name ? String(errors.name.message) : ""}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        defaultValue={description}
        render={({ field }) => (
          <TextField {...field} label="Opis" multiline rows={4} />
        )}
      />
      <Button type="submit" variant="contained" color="primary">
        {inEditMode ? "Zaktualizuj parametr" : "Utwórz parametr"}
      </Button>
    </form>
  );
}

export default CategoryForm;
