import { Close } from "@mui/icons-material";
import { Button, InputLabel } from "@mui/material";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

function Banners() {
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      banners: [{ desktopImage: null, mobileImage: null }],
    },
  });

  // Using useFieldArray to dynamically add/remove image fields
  const { fields, append, remove } = useFieldArray({
    control,
    name: "banners",
  });

  // Handle form submission
  const onSubmit = (data: any) => {
    console.log("Form Data", data);
    // You can handle form submission logic here
  };

  return (
    <div className="divide-y mt-5">
      <h2 className="text-red-500 text-2xl font-bold">
        UWAGA! Sekcja w trakcie implementacji (nie działa)
      </h2>
      <div className="p-2">
        <h2 className="text-2xl font-bold">
          Zdjęcia do panelu reklamowego dla strony głownej
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((item, index) => (
            <div key={item.id} className="mt-4 p-2 border-2 image-input-field">
              <p className="text-gray-500">Zdjęcia desktop (1536x384px)</p>

              {/* Desktop Image */}
              <Controller
                name={`banners.${index}.desktopImage`}
                control={control}
                defaultValue={null}
                render={({ field: { onChange } }) => (
                  <div>
                    <InputLabel>Dodaj baner reklamowy</InputLabel>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e: any) => onChange(e.target.files[0])}
                      style={{ marginTop: "8px" }}
                    />
                  </div>
                )}
              />

              <p className="text-gray-500 mt-2">Zdjęcia mobile (768x384px)</p>

              {/* Mobile Image */}
              <Controller
                name={`banners.${index}.mobileImage`}
                control={control}
                defaultValue={null}
                render={({ field: { onChange } }) => (
                  <div>
                    <InputLabel>Dodaj baner mobilny</InputLabel>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e: any) => onChange(e.target.files[0])}
                      style={{ marginTop: "8px" }}
                    />
                  </div>
                )}
              />

              <Button
                variant="text"
                size="small"
                color="error"
                endIcon={<Close />}
                style={{ padding: 0, fontSize: "small", marginTop: "8px" }}
                onClick={() => remove(index)}
              >
                Usuń zdjęcie
              </Button>
            </div>
          ))}

          <Button
            variant="contained"
            color="secondary"
            onClick={() => append({ desktopImage: null, mobileImage: null })}
            style={{ marginTop: "10px" }}
          >
            Dodaj kolejny baner
          </Button>

          <Button
            variant="outlined"
            color="success"
            style={{ padding: "5px 10px", marginTop: "10px" }}
            type="submit"
          >
            Zapisz zdjęcia
          </Button>
        </form>
      </div>
    </div>
  );
}

export default Banners;
