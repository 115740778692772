import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import ParameterForm from "../../../forms/ParameterForm";
import { Parameter } from "../../../models/Parameter";
import { useParameter } from "../../../context/ParameterContext";

function EditParameter() {
  const { id } = useParams<{ id: string }>();
  const { getParameter } = useParameter();
  const [parameter, setParameter] = React.useState<Parameter | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchParameter() {
      if (id) {
        try {
          const parameter = await getParameter(parseInt(id));
          setParameter(parameter);
        } catch (error: any) {
          toast.error("Parametr nie istnieje");
          navigate("/admin/parameters");
        }
      }
    }

    fetchParameter();
  }, [id]);

  return (
    <div>
      <IconButton component={Link} to="/admin/parameters">
        <ArrowBackIcon />
      </IconButton>

      {parameter && (
        <ParameterForm
          categoryId={parameter?.categoryId!}
          id={parameter?.id}
          name={parameter?.name}
          description={parameter?.description!}
          inEditMode={true}
        />
      )}
    </div>
  );
}

export default EditParameter;
