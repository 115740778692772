import { Product } from "./Product";

export type Inquiry = {
  id: number;
  name: string;
  email: string;
  nip?: string;
  remarks?: string;
  status: InquiryStatus;
  createdAt: string;

  readonly products?: InquiryProduct[];
};

export enum InquiryStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  CLOSED = "closed",
}

export type InquiryProduct = {
  inquiryId: number;
  productId: number;
  quantity: number;

  readonly product?: Product;
  readonly inquiry?: Inquiry;
};
