import * as React from "react";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Parameter } from "../models/Parameter";
import { useParameter } from "../context/ParameterContext";
import { Button } from "@mui/material";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ParameterCard({
  parameter,
  getCategoriesData,
  setUsingSearch,
  showCategory = false,
}: {
  parameter: Parameter;
  getCategoriesData: any;
  setUsingSearch: any;
  showCategory?: boolean;
}) {
  const [expanded, setExpanded] = React.useState(false);

  const { deleteParameter } = useParameter();

  const handleDeleteParameter = async () => {
    const toastId = toast.loading("Usuwanie...", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
    });

    try {
      await deleteParameter(parameter.id);
      toast.update(toastId, {
        render: "Parametr usunięty!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error: Error | any) {
      console.error("Failed to delete Parameter", error);
      toast.update(toastId, {
        render: "Nie udało się usunąć parametru",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleDeleteClick = async () => {
    confirmAlert({
      title: "Potwierdź usunięcie",
      message: "Czy na pewno chcesz usunąć parametr?",
      buttons: [
        {
          label: "Tak",
          onClick: () => handleDeleteParameter(),
        },
        {
          label: "Nie",
        },
      ],
    });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="shadow-sm">
      {showCategory && (
        <div className="p-1">
          <div className="flex items-center space-x-2 bg-gray-100 px-1">
            {parameter.category ? (
              <Button
                variant="text"
                color="primary"
                size="small"
                style={{ fontSize: "small", padding: "2px" }}
                onClick={() => {
                  getCategoriesData(parameter.category.id!);
                  setUsingSearch(false);
                }}
              >
                Kategoria nadrzędna ({parameter.category.name})
              </Button>
            ) : (
              <Button
                variant="text"
                color="secondary"
                size="small"
                style={{ fontSize: "small", padding: "2px" }}
                onClick={() => {
                  getCategoriesData();
                  setUsingSearch(false);
                }}
              >
                Kategoria główna
              </Button>
            )}
          </div>
        </div>
      )}
      <div>
        <div className="flex justify-between items-center w-full h-fit px-1">
          <div className="flex items-center space-x-2">
            <h2 className="text-lg">{parameter.name}</h2>
          </div>
          <CardActions disableSpacing>
            <div>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
              <IconButton
                aria-label="edit"
                component={Link}
                to={`/admin/parameters/edit/${parameter.id}`}
              >
                <Edit />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="warning"
                onClick={handleDeleteClick}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </CardActions>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography padding={0}>
              {parameter.description ? parameter.description : "Brak opisu"}
            </Typography>
          </CardContent>
        </Collapse>
      </div>
    </div>
  );
}
