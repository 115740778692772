import {
  Pagination,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import useDebounce from "../../../hooks/useDebounce";
import { useInquiry } from "../../../context/InquiryContext";
import { Inquiry, InquiryStatus } from "../../../models/Inquiry";
import InquiryCard from "../../../components/InquiryCard";
import translateStatusToPolish from "../../../helperFunctions/translateStatusToPolish";

function Inquiries() {
  const { getInquiries } = useInquiry();

  const { control } = useForm();
  const [query, setQuery] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalInquiries, setTotalInquiries] = React.useState(0);
  const [inquiries, setInquiries] = React.useState<Inquiry[]>([]);
  const [status, setStatus] = React.useState<InquiryStatus | null>(
    InquiryStatus.PENDING
  );
  const [sort, setSort] = React.useState<string>("newest");
  const [limit, setLimit] = React.useState(10);

  const debouncedQuery = useDebounce(query, 500);

  const getInquiriesData = async (
    page: number,
    query: string,
    status: InquiryStatus | null,
    sort: string,
    limit: number
  ) => {
    const { inquiries, totalPages, totalInquiries } = await getInquiries({
      search: query,
      status: status,
      page: page,
      limit: limit,
    });
    setInquiries(inquiries);
    setCurrentPage(page);
    setTotalPages(totalPages);
    setTotalInquiries(totalInquiries);
  };

  useEffect(() => {
    getInquiriesData(currentPage, debouncedQuery, status, sort, limit);
  }, [debouncedQuery, status, sort, limit, currentPage]);

  const handleStatusChange = () => {
    getInquiriesData(currentPage, debouncedQuery, status, sort, limit);
  };

  return (
    <div className="flex flex-col items-stretch space-y-5">
      <div className="space-y-2">
        <h3 className="text-2xl">Lista zapytań</h3>
        <Controller
          name="search"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Szukaj zapytania (nazwa, email, nip)"
              variant="outlined"
              size="small"
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          )}
        />

        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value as InquiryStatus)}
            label="Status"
          >
            <MenuItem>Wszystkie</MenuItem>
            {Object.values(InquiryStatus).map((statusOption) => (
              <MenuItem key={statusOption} value={statusOption}>
                {translateStatusToPolish(statusOption)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Sortowanie</InputLabel>
          <Select
            value={sort}
            onChange={(e) => setSort(e.target.value)}
            label="Sortowanie"
          >
            <MenuItem value="newest">Najnowsze</MenuItem>
            <MenuItem value="oldest">Najstarsze</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Limit</InputLabel>
          <Select
            value={limit}
            onChange={(e) => setLimit(Number(e.target.value))}
            label="Limit"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>

        <p>
          Wyświetlono {inquiries ? inquiries.length : 0} z {totalInquiries}{" "}
          zapytań
        </p>
        <ul className="space-y-2 p-2">
          {inquiries.map((inquiry) => (
            <InquiryCard
              key={inquiry.id}
              inquiry={inquiry}
              onStatusChange={handleStatusChange}
            />
          ))}
        </ul>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, page) =>
            getInquiriesData(page, query, status, sort, limit)
          }
          color="primary"
        />
      </div>
    </div>
  );
}

export default Inquiries;
