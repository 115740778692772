import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOffer } from "../context/OfferContext";
import { Offer as OfferModel } from "../models/Offer";
import { Link } from "react-router-dom";
import { Mail, Phone } from "@mui/icons-material";

function Offer() {
  const { id } = useParams();
  const { getOffer } = useOffer();

  const [offer, setOffer] = React.useState<OfferModel | null>(null);

  useEffect(() => {
    const fetchOffer = async () => {
      setOffer(await getOffer(parseInt(id!)));
    };
    fetchOffer();
  }, []);

  if (!offer) {
    return <div>Ładowanie oferty...</div>;
  }

  return (
    <div className="p-5">
      <div className="flex justify-between items-start">
        <div>
          <h1 className="text-4xl font-semibold">Oferta numer: {offer?.id}</h1>
          <p>
            Data złożenia oferty:{" "}
            {new Date(offer!.sentAt).toLocaleDateString("pl")}
          </p>
          <p>
            Data ważności oferty:{" "}
            {new Date(offer!.validUntil).toLocaleDateString("pl")}
          </p>
          {new Date(offer!.validUntil).getTime() < Date.now() && (
            <p className="text-red-500">Oferta wygasła</p>
          )}
          <br />
          <h2 className="text-2xl font-semibold">
            Cena całkowita: {offer.price.toFixed(2)} PLN
          </h2>
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-gray-500">Skontaktuj się z nami w celu zakupu</p>
          <a
            className="p-3 rounded-full hover:bg-primary hover:text-white text-primary flex items-center justify-center transition-all duration-100"
            href="tel:123456789"
          >
            <Phone style={{ fontSize: 45 }} />
          </a>
          <a
            className="p-3 rounded-full hover:bg-primary hover:text-white text-primary flex items-center justify-center transition-all duration-100"
            href="mailto:kontakt@eltimex.pl"
          >
            <Mail style={{ fontSize: 45 }} />
          </a>
        </div>
      </div>
      <br />
      <br />
      <h2 className="text-2xl font-semibold">Wiadomość dotycząca oferty:</h2>
      <p>{offer?.offerDetails}</p>
      <br />
      <br />
      <h2 className="text-2xl font-semibold">Produkty w ofercie:</h2>
      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead className="bg-gray-100 text-left">
          <tr className="border-b border-gray-300">
            <th className="px-4 py-2">Ilość z zapytania</th>
            <th className="px-4 py-2">Produkt</th>
            <th className="px-4 py-2">Proponowana cena</th>
          </tr>
        </thead>
        <tbody>
          {offer?.offerProducts.map((product) => (
            <tr key={product.product.id} className="border-b border-gray-200">
              <td className="px-4 py-2 text-center">{product.quantity}</td>
              <td className="px-4 py-2">
                <div className="flex items-center space-x-4">
                  <img
                    src={product.product.mobilePrimaryImage}
                    alt={product.product.name}
                    className="w-16 h-16 object-cover"
                  />
                  <Link
                    to={`/product/${product.product.id}`}
                    className="hover:underline"
                  >
                    {product.product.name}
                  </Link>
                </div>
              </td>
              <td className="px-4 py-2 text-left">
                {product.pricePerProduct.toFixed(2)} PLN
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Offer;
