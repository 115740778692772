import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useCategory } from "../../../context/CategoryContext";
import { Category } from "../../../models/Category";
import { toast } from "react-toastify";
import ParameterForm from "../../../forms/ParameterForm";

function AddParameter() {
  const { id } = useParams<{ id: string }>();
  const { getCategory } = useCategory();
  const [category, setCategory] = React.useState<Category | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchCategory() {
      if (id) {
        try {
          const category = await getCategory(parseInt(id));
          setCategory(category);
        } catch (error: any) {
          toast.error("Kategoria nie istnieje");
          navigate("/admin/parameters");
        }
      }
    }

    fetchCategory();
  }, [id]);

  return (
    <div>
      <IconButton component={Link} to="/admin/parameters">
        <ArrowBackIcon />
      </IconButton>
      <h2 className="text-2xl mb-2">
        {id
          ? `Dodaj parametr dla: ${category?.name}`
          : "Dodaj parametr globalny"}
      </h2>
      <ParameterForm categoryId={category?.id} />
    </div>
  );
}

export default AddParameter;
