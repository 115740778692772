import React, { useState } from "react";
import { Inquiry, InquiryStatus } from "../models/Inquiry";
import {
  Button,
  CardActions,
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useInquiry } from "../context/InquiryContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import translateStatusToPolish from "../helperFunctions/translateStatusToPolish";

type Props = {
  inquiry: Inquiry;
  onStatusChange: () => void;
};

const InquiryCard: React.FC<Props> = ({ inquiry, onStatusChange }) => {
  const { changeInquiryStatus } = useInquiry();

  const [open, setOpen] = useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      status: inquiry.status,
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: { status: InquiryStatus }) => {
    const toastId = toast.loading("Zapisywanie...", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
    });
    try {
      await changeInquiryStatus(inquiry.id, data.status);
      toast.update(toastId, {
        render: "Status zapisany!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      onStatusChange();
    } catch (error: Error | any) {
      console.error("Failed to change Inquiry status", error);
      toast.update(toastId, {
        render: "Nie udało się zapisać statusu",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
    setOpen(false);
  };

  return (
    <div className="shadow-sm p-1">
      <div>
        <p>
          Data zapytania: {new Date(inquiry.createdAt).toLocaleString("pl-PL")}
        </p>
      </div>
      <div className="flex justify-between items-center w-full h-16 p-1">
        <div className="flex items-center space-x-2">
          <p className="font-semibold">{inquiry.name}</p>
          <p className="text-gray-500">{inquiry.email}</p>
          {inquiry.nip && <p className="text-gray-500">{inquiry.nip}</p>}
        </div>
        <CardActions>
          <p className="mr-5">
            Status:{" "}
            <span
              className={`font-semibold ${
                inquiry.status === InquiryStatus.PENDING
                  ? "text-yellow-500"
                  : inquiry.status === InquiryStatus.IN_PROGRESS
                  ? "text-blue-500"
                  : inquiry.status === InquiryStatus.COMPLETED
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {translateStatusToPolish(inquiry.status)}
            </span>
          </p>
          <Button
            variant="text"
            color="primary"
            size="small"
            style={{ fontSize: "small", padding: "2px" }}
            component={Link}
            to={`${inquiry.id}`}
          >
            Zobacz szczegóły zapytania
          </Button>
          {inquiry.status !== InquiryStatus.CLOSED &&
            inquiry.status !== InquiryStatus.IN_PROGRESS && (
              <Button
                variant="text"
                color="primary"
                size="small"
                style={{ fontSize: "small", padding: "2px" }}
                onClick={handleClickOpen}
              >
                Zmień status
              </Button>
            )}
          {inquiry.status === InquiryStatus.IN_PROGRESS && (
            <Button
              variant="text"
              color="success"
              size="small"
              style={{ fontSize: "small", padding: "2px" }}
              component={Link}
              to={`/admin/offers/add/${inquiry.id}`}
            >
              Utwórz ofertę
            </Button>
          )}
        </CardActions>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <h2 className="font-semibold text-xl p-2">Zmień status</h2>
        <p className="p-2">
          Uwaga! Zmiana statusu zapytania spowoduje wysłanie powiadomienia do
          klienta. Zmiana statusu jest nieodwracalna, a w przypadku zamknięcia
          zapytania nie będzie możliwości jego ponownego otwarcia.
        </p>
        <div className="p-2">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select {...field} label="Status">
                    <MenuItem value={InquiryStatus.IN_PROGRESS}>
                      {translateStatusToPolish(InquiryStatus.IN_PROGRESS)}
                    </MenuItem>
                    <MenuItem value={InquiryStatus.CLOSED}>
                      {translateStatusToPolish(InquiryStatus.CLOSED)}
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Anuluj
              </Button>
              <Button type="submit" color="primary">
                Zapisz
              </Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default InquiryCard;
