import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useInquiry } from "../../../context/InquiryContext";
import { toast } from "react-toastify";
import { Inquiry, InquiryStatus } from "../../../models/Inquiry";
import translateStatusToPolish from "../../../helperFunctions/translateStatusToPolish";
import { Link } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Add as AddIcon } from "@mui/icons-material";

function InquiryPage() {
  const navigate = useNavigate();

  const { inquiryId } = useParams();
  const { getInquiry } = useInquiry();

  const [inquiry, setInquiry] = useState<Inquiry | null>(null);

  useEffect(() => {
    async function fetchInquiry() {
      const toastId = toast.loading("Ładowanie zapytania...");
      try {
        const inquiry = await getInquiry(parseInt(inquiryId!));
        setInquiry(inquiry);
        toast.dismiss(toastId);
      } catch (error) {
        toast.error("Nie udało się załadować zapytania");
        navigate("/admin/inquiries");
      }
    }

    if (!inquiryId) {
      toast.error("Nie znaleziono zapytania");
      navigate("/admin/inquiries");
    }

    fetchInquiry();
  }, [inquiryId]);

  return (
    <div>
      <IconButton component={Link} to="/admin/inquiries">
        <ArrowBackIcon />
      </IconButton>
      <div className="flex justify-between">
        <div>
          <h1 className="text-2xl font-bold mb-4">
            Zapytanie numer: {inquiry?.id}
          </h1>
          <div className="space-y-1">
            <p className="">
              Data zapytania:{" "}
              {new Date(inquiry?.createdAt!).toLocaleString("pl-PL")}
            </p>
            <p className="">
              Imię i nazwisko/Nazwa firmy: {inquiry?.name} ({inquiry?.email})
            </p>
            {inquiry?.nip && <p className="">NIP: {inquiry?.nip}</p>}
            <p className="">Uwagi: {inquiry?.remarks}</p>
          </div>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4">
            Status:{" "}
            <span
              className={`${
                inquiry?.status === InquiryStatus.PENDING
                  ? "text-yellow-500"
                  : inquiry?.status === InquiryStatus.IN_PROGRESS
                  ? "text-blue-500"
                  : inquiry?.status === InquiryStatus.COMPLETED
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {translateStatusToPolish(inquiry?.status!)}
            </span>
          </h2>
        </div>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-4 mt-8">Produkty w zapytaniu</h2>
        <div className="divide-y divide-gray-200">
          {inquiry?.products?.map((product) => (
            <div
              key={product.productId}
              className="flex justify-between items-center"
            >
              <Link
                className="flex items-center space-x-2 hover:drop-shadow-xl"
                to={`/product/${product.productId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={product.product?.mobilePrimaryImage}
                  alt={product.product?.name}
                  height={125}
                  width={125}
                  className="h-16 w-16 object-contain object-center"
                />
                <p className="font-semibold">{product.product?.name}</p>
              </Link>
              <p>{product.quantity} szt.</p>
            </div>
          ))}
        </div>
      </div>
      {inquiry?.status === InquiryStatus.IN_PROGRESS && (
        <div className="flex justify-end mt-5">
          <Button
            variant="outlined"
            color="primary"
            style={{ padding: "10px 15px" }}
            endIcon={<AddIcon />}
            onClick={() => navigate(`/admin/offers/add/${inquiryId}`)}
          >
            Utwórz ofertę
          </Button>
        </div>
      )}
    </div>
  );
}

export default InquiryPage;
