import {
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import useDebounce from "../../../hooks/useDebounce";
import { useOffer } from "../../../context/OfferContext";
import { Offer } from "../../../models/Offer";
import OfferCard from "../../../components/OfferCard";

function Offers() {
  const { control } = useForm();

  const [query, setQuery] = React.useState("");
  const [limit, setLimit] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalOffers, setTotalOffers] = React.useState(0);

  const [offers, setOffers] = React.useState<Offer[]>([]);

  const debouncedQuery = useDebounce(query, 500);

  const { getOffers } = useOffer();
  const getOffersData = async (page: number, query: string, limit: number) => {
    const { offers, totalPages } = await getOffers(limit, page, query);
    setOffers(offers);
    setCurrentPage(page);
    setTotalPages(totalPages);
    setTotalOffers(offers.length);
  };

  useEffect(() => {
    getOffersData(currentPage, debouncedQuery, limit);
  }, [debouncedQuery, limit, currentPage]);

  return (
    <div className="flex flex-col items-stretch space-y-5">
      <div className="space-y-2">
        <h3 className="text-2xl">Lista ofert</h3>
        <Controller
          name="search"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Szukaj oferty (numer zapytania)"
              variant="outlined"
              size="small"
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          )}
        />

        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Limit</InputLabel>
          <Select
            value={limit}
            onChange={(e) => setLimit(Number(e.target.value))}
            label="Limit"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>

        <p>
          Wyświetlono {offers ? offers.length : 0} z {totalOffers} ofert
        </p>
        <ul className="space-y-2 p-2">
          {offers.map((offer) => (
            <OfferCard key={offer.id} offer={offer} />
          ))}
        </ul>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
          color="primary"
        />
      </div>
    </div>
  );
}

export default Offers;
