import React, { createContext, useContext, ReactNode } from "react";
import { Product } from "../models/Product";

interface ShoppingListContextType {
  shoppingList: {
    id: number;
    name: string;
    price: number;
    quantity: number;
    image: string;
  }[];
  addToShoppingList: (product: Product) => void;
  removeProductFromShoppingList: (productId: number) => void;
  setProductQuantity: (productId: number, quantity: number) => void;
  getShoppingList: () => {
    id: number;
    name: string;
    price: number;
    quantity: number;
    image: string;
  }[];
}

const ShoppingListContext = createContext<ShoppingListContextType | undefined>(
  undefined
);

export const useShoppingList = () => {
  const context = useContext(ShoppingListContext);
  if (!context) {
    throw new Error(
      "useShoppingList must be used within a ShoppingListProvider"
    );
  }
  return context;
};

interface ShoppingListProviderProps {
  children: ReactNode;
}

export const ShoppingListProvider: React.FC<ShoppingListProviderProps> = ({
  children,
}) => {
  const [shoppingList, setShoppingList] = React.useState<
    {
      id: number;
      name: string;
      price: number;
      quantity: number;
      image: string;
    }[]
  >([]);

  const addToShoppingList = (product: Product) => {
    const shoppingList = localStorage.getItem("shoppingList");
    let updatedShoppingList: {
      id: number;
      name: string;
      price: number;
      quantity: number;
      image: string;
    }[] = [];
    if (shoppingList) {
      updatedShoppingList = JSON.parse(shoppingList);
    }
    const productIndex = updatedShoppingList.findIndex(
      (p) => p.id === product.id
    );

    if (productIndex !== -1) {
      updatedShoppingList[productIndex].quantity += 1;
      localStorage.setItem("shoppingList", JSON.stringify(updatedShoppingList));
      updateShoppingList();
      return;
    }

    updatedShoppingList.push({
      id: product.id,
      name: product.name,
      price: product.price,
      quantity: 1,
      image: product.mobilePrimaryImage,
    });
    localStorage.setItem("shoppingList", JSON.stringify(updatedShoppingList));
    updateShoppingList();
  };

  const removeProductFromShoppingList = (productId: number) => {
    const shoppingList = localStorage.getItem("shoppingList");
    let updatedShoppingList: {
      id: number;
      name: string;
      price: number;
      quantity: number;
      image: string;
    }[] = [];
    if (shoppingList) {
      updatedShoppingList = JSON.parse(shoppingList);
    }
    const productIndex = updatedShoppingList.findIndex(
      (product) => product.id === productId
    );
    updatedShoppingList.splice(productIndex, 1);
    localStorage.setItem("shoppingList", JSON.stringify(updatedShoppingList));
    updateShoppingList();
  };

  const getShoppingList = () => {
    const shoppingList = localStorage.getItem("shoppingList");
    if (shoppingList) {
      return JSON.parse(shoppingList);
    }
    return [];
  };

  const setProductQuantity = (productId: number, quantity: number) => {
    const shoppingList = localStorage.getItem("shoppingList");
    let updatedShoppingList: {
      id: number;
      name: string;
      price: number;
      quantity: number;
      image: string;
    }[] = [];
    if (shoppingList) {
      updatedShoppingList = JSON.parse(shoppingList);
    }
    const productIndex = updatedShoppingList.findIndex(
      (product) => product.id === productId
    );
    updatedShoppingList[productIndex].quantity = quantity;
    localStorage.setItem("shoppingList", JSON.stringify(updatedShoppingList));
  };

  const updateShoppingList = () => {
    const shoppingList = getShoppingList();
    setShoppingList(shoppingList);
  };

  React.useEffect(() => {
    updateShoppingList();
  }, []);

  return (
    <ShoppingListContext.Provider
      value={{
        shoppingList: getShoppingList(),
        addToShoppingList,
        removeProductFromShoppingList,
        getShoppingList,
        setProductQuantity,
      }}
    >
      {children}
    </ShoppingListContext.Provider>
  );
};
