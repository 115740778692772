import { Close, FilterAlt } from "@mui/icons-material";
import { Checkbox, FormControlLabel, IconButton, Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Parameter } from "../models/Parameter";

type Props = {
  parameters: Parameter[];
  // producers: Producer[];
  // encodedProducers: string;
  encodedFilters: string;
  setPriceRange: (range: number[]) => void;
  currentPriceRange: number[];
  totalPriceRange: number[];
  handleSaveFilters: (filters: string) => void;
};

function Filters({
  parameters,
  // producers,
  // encodedProducers,
  encodedFilters,
  setPriceRange,
  currentPriceRange,
  totalPriceRange,
  handleSaveFilters,
}: Props) {
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string[];
  }>({});

  // const [selectedProducers, setSelectedProducers] = useState<string[]>([]);

  const handlePriceChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const minDistance = Math.floor(
      (totalPriceRange[1] - totalPriceRange[0]) * 0.1
    );

    if (activeThumb === 0) {
      setPriceRange([
        Math.min(newValue[0], currentPriceRange[1] - minDistance),
        currentPriceRange[1],
      ]);
    } else {
      setPriceRange([
        currentPriceRange[0],
        Math.max(newValue[1], currentPriceRange[0] + minDistance),
      ]);
    }
  };

  const decodeFilters = (encodedFilters: string) => {
    const decodedFilters = JSON.parse(decodeURIComponent(encodedFilters));
    setSelectedFilters(decodedFilters);

    // const decodedProducers = JSON.parse(decodeURIComponent(encodedProducers));
    // setSelectedProducers(decodedProducers);
  };

  const getUpdatedFilters = () => {
    return {
      filters: encodeURIComponent(JSON.stringify(selectedFilters)),
      // producers: encodeURIComponent(JSON.stringify(selectedProducers)),
    };
  };

  const removeEmptyFilters = () => {
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev };
      Object.keys(updatedFilters).forEach((key) => {
        if (updatedFilters[key].length === 0) {
          delete updatedFilters[key];
        }
      });
      return updatedFilters;
    });
  };

  const handleCheckboxChange = (parameterId: string, value: string) => {
    setSelectedFilters((prev) => {
      const prevValues = prev[parameterId] || [];
      if (prevValues.includes(value)) {
        return {
          ...prev,
          [parameterId]: prevValues.filter((val) => val !== value),
        };
      }
      return {
        ...prev,
        [parameterId]: [...prevValues, value],
      };
    });
    removeEmptyFilters();
  };

  const clearFilters = () => {
    setSelectedFilters({});
    setPriceRange(totalPriceRange);
  };

  useEffect(() => {
    encodedFilters && decodeFilters(encodedFilters);
  }, []);

  return (
    <div className="pt-4 space-y-3">
      <h3 className="font-semibold text-xl">Filtry</h3>
      <div className="flex items-center space-x-2">
        <IconButton
          onClick={() => {
            clearFilters();
          }}
        >
          <Close fontSize="small" color="error" />
        </IconButton>
        <p>Wyczyść filtry</p>
      </div>
      <div>
        <p className="font-semibold">Cena</p>
        <Slider
          getAriaLabel={() => "Price"}
          value={currentPriceRange}
          onChange={handlePriceChange}
          valueLabelDisplay="auto"
          disableSwap
          min={totalPriceRange[0]}
          max={totalPriceRange[1]}
        />
      </div>
      {/* <div>
        <p className="font-semibold">Producenci</p>
        <div className="pl-4 flex flex-col">
          {producers.map((producer) => (
            <FormControlLabel
              style={{ padding: "0" }}
              key={producer.id}
              control={
                <Checkbox
                  size="small"
                  checked={
                    selectedProducers.includes(producer.id.toString()) || false
                  }
                  onChange={() =>
                    setSelectedProducers((prev) => {
                      if (prev.includes(producer.id.toString())) {
                        return prev.filter(
                          (id) => id !== producer.id.toString()
                        );
                      }
                      return [...prev, producer.id.toString()];
                    })
                  }
                />
              }
              label={producer.name}
            />
          ))}
        </div>
      </div> */}

      <div>
        {parameters.map((parameter) => (
          <div key={parameter.id}>
            <p className="font-semibold">{parameter.name}</p>
            <div className="pl-4 flex flex-col">
              {parameter.parameterValues.map((value) => (
                <FormControlLabel
                  style={{ padding: "0" }}
                  key={value.value}
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        selectedFilters[parameter.id]?.includes(value.value) ||
                        false
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          parameter.id.toString(),
                          value.value
                        )
                      }
                    />
                  }
                  label={value.value}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <button
        className="p-3 rounded-md w-full bg-primary text-white flex items-center justify-center space-x-2 mt-2 hover:bg-secondary"
        onClick={() => {
          const updatedFilters = getUpdatedFilters();
          handleSaveFilters(updatedFilters.filters);
        }}
      >
        Zastosuj filtry
        <FilterAlt />
      </button>
    </div>
  );
}

export default Filters;
