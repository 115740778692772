import { InquiryStatus } from "../models/Inquiry";

const translateStatusToPolish = (status: InquiryStatus): string => {
  switch (status) {
    case InquiryStatus.PENDING:
      return "Oczekujące";
    case InquiryStatus.IN_PROGRESS:
      return "W toku";
    case InquiryStatus.COMPLETED:
      return "Zakończone";
    case InquiryStatus.CLOSED:
      return "Zamknięte";
    default:
      return status;
  }
};

export default translateStatusToPolish;
