import React, { useEffect, useState } from "react";
import OfferForm from "../../../forms/OfferForm";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useInquiry } from "../../../context/InquiryContext";
import { Inquiry } from "../../../models/Inquiry";
import { toast } from "react-toastify";

function AddOffer() {
  const navigate = useNavigate();

  const { inquiryId } = useParams();
  const { getInquiry } = useInquiry();

  const [inquiry, setInquiry] = useState<Inquiry | null>(null);

  useEffect(() => {
    async function fetchInquiry() {
      const toastId = toast.loading("Ładowanie zapytania...");
      try {
        const inquiry = await getInquiry(parseInt(inquiryId!));
        setInquiry(inquiry);
        toast.dismiss(toastId);
      } catch (error) {
        toast.error("Nie udało się załadować zapytania");
        navigate("/admin/inquiries");
      }
    }

    if (!inquiryId) {
      toast.error("Nie znaleziono zapytania");
      navigate("/admin/inquiries");
    }

    fetchInquiry();
  }, []);

  return (
    <div>
      <IconButton component={Link} to="/admin/offers">
        <ArrowBack />
      </IconButton>
      <h2 className="text-2xl mb-2">
        Dodaj ofertę dla zapytania numer: {inquiry?.id}
      </h2>
      <p className="text-base mt-4">Szczegóły zapytania:</p>
      <table
        className="border border-gray-200 divide-y divide-gray-200 mb-2"
        key={inquiry?.id}
      >
        <tr className="bg-white">
          <td className="px-6 py-4 text-sm font-medium text-gray-900">
            Data zapytania
          </td>
          <td className="px-6 py-4 text-sm text-gray-500">
            {new Date(inquiry?.createdAt!).toLocaleString("pl-PL")}
          </td>
        </tr>
        <tr className="bg-white">
          <td className="px-6 py-4 text-sm font-medium text-gray-900">
            Imię i nazwisko/Nazwa firmy
          </td>
          <td className="px-6 py-4 text-sm text-gray-500">
            {inquiry?.name} ({inquiry?.email})
          </td>
        </tr>
        {inquiry?.nip && (
          <tr className="bg-white">
            <td className="px-6 py-4 text-sm font-medium text-gray-900">NIP</td>
            <td className="px-6 py-4 text-sm text-gray-500">{inquiry?.nip}</td>
          </tr>
        )}
        <tr className="bg-white">
          <td className="px-6 py-4 text-sm font-medium text-gray-900">Uwagi</td>
          <td className="px-6 py-4 text-sm text-gray-500">
            {inquiry?.remarks}
          </td>
        </tr>
      </table>
      {inquiry && <OfferForm inquiry={inquiry} />}
    </div>
  );
}

export default AddOffer;
