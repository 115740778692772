import { Controller } from "react-hook-form";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import useDebounce from "../../hooks/useDebounce";

import { Producer } from "../../models/Producer";
import { useProducer } from "../../context/ProducerContext";

const ProducerSelect = ({ control, errors }: { control: any; errors: any }) => {
  const [producers, setProducers] = useState<Producer[]>([]);
  const { getProducers } = useProducer();

  const [inputValue, setInputValue] = useState("");
  const [selectedProducer, setSelectedProducer] = useState<Producer | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const debouncedQuery = useDebounce(inputValue, 1000);

  useEffect(() => {
    const fetchProducers = async () => {
      setLoading(true);
      const response = await getProducers(1, 10, debouncedQuery);
      setProducers(response.producers);
      setLoading(false);
    };

    if (debouncedQuery || debouncedQuery === "") {
      fetchProducers();
    }
  }, [debouncedQuery]);

  return (
    <Controller
      name="producerId"
      control={control}
      rules={{ required: "Producent jest wymagany" }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          value={selectedProducer}
          inputValue={inputValue}
          options={producers}
          loading={loading}
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            setSelectedProducer(null);
          }}
          onChange={(event, newValue) => {
            setSelectedProducer(newValue);
            setInputValue(newValue ? newValue.name : "");
            field.onChange(newValue ? newValue.id : "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Producent"
              variant="outlined"
              error={!!errors.producerId}
              helperText={
                errors.producerId ? String(errors.producerId.message) : ""
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default ProducerSelect;
