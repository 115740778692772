export enum ContentType {
  TEXT = "text",
  HTML = "html",
  IMAGE_URL = "image_url",
}

export interface Content {
  id: number;
  content_key: string;
  content_type: ContentType;
  content_data: string;

  created_at: string;
  updated_at: string;
}
