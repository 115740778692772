import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { User } from "../models/User";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (
    name: string,
    surname: string,
    email: string,
    password: string
  ) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    if (storedToken && tokenExpiration) {
      const now = new Date();
      const expirationDate = new Date(tokenExpiration);

      if (now < expirationDate) {
        setToken(storedToken);
      } else {
        logout();
      }
    }
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const isAuthenticated = !!token;

  const apiUrl = process.env.REACT_APP_API_URL;

  const register = async (
    name: string,
    surname: string,
    email: string,
    password: string
  ) => {
    try {
      const response = await fetch(`${apiUrl}/api/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, surname, email, password }),
      });

      if (!response.ok) {
        throw new Error("Błąd podczas rejestracji");
      }
    } catch (error: any) {
      if (error.message !== "Błąd podczas rejestracji") {
        throw new Error("Błąd serwera");
      } else {
        throw error;
      }
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setToken(data.token);

        const decodedToken = JSON.parse(atob(data.token.split(".")[1]));
        setUser(decodedToken.user);

        const expiration = new Date(decodedToken.exp * 1000);

        localStorage.setItem("token", data.token);
        localStorage.setItem("tokenExpiration", expiration.toISOString());
        localStorage.setItem("user", JSON.stringify(decodedToken.user));
      } else {
        throw new Error("Niepoprawny email lub hasło");
      }
    } catch (error: any) {
      if (error.message !== "Niepoprawny email lub hasło") {
        throw new Error("Wystąpił błąd podczas logowania");
      } else {
        throw error;
      }
    }
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
    localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{ user, token, isAuthenticated, login, register, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
