import React, { createContext, useContext, ReactNode } from "react";
import axios from "axios";
import { withAuth } from "../middleware/withAuth";
import { Content, ContentType } from "../models/Content";

interface ContentContextType {
  createContent: (
    content_key: string,
    content_type: ContentType,
    content_data: string
  ) => Promise<void>;
  updateContent: (
    id: number,
    content_key: string,
    content_type: ContentType,
    content_data: string
  ) => Promise<void>;
  getAllContent: () => Promise<Content[]>;
  getContentByContentKey: (content_key: string) => Promise<Content[]>;
  deleteContent: (id: number) => Promise<void>;
}

const ContentContext = createContext<ContentContextType | undefined>(undefined);

export const useContent = () => {
  const context = useContext(ContentContext);
  if (!context) {
    throw new Error("useContent must be used within a ContentProvider");
  }
  return context;
};

interface ContentProviderProps {
  children: ReactNode;
}

export const ContentProvider: React.FC<ContentProviderProps> = ({
  children,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const createContent = async (
    content_key: string,
    content_type: ContentType,
    content_data: string
  ) => {
    try {
      const config = await withAuth({
        method: "post",
        url: `${apiUrl}/api/content`,
        headers: { "Content-Type": "application/json" },
        data: {
          content_key,
          content_type,
          content_data,
        },
      });
      await axios(config);
    } catch (error: any) {
      console.error("Failed to create content", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const updateContent = async (
    id: number,
    content_key: string,
    content_type: ContentType,
    content_data: string
  ) => {
    try {
      const config = await withAuth({
        method: "put",
        url: `${apiUrl}/api/content/${id}`,
        headers: { "Content-Type": "application/json" },
        data: {
          content_key,
          content_type,
          content_data,
        },
      });
      await axios(config);
    } catch (error: any) {
      console.error("Failed to update content", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getAllContent = async (): Promise<Content[]> => {
    try {
      const config = await withAuth({
        method: "get",
        url: `${apiUrl}/api/content`,
      });
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch content", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getContentByContentKey = async (
    content_key: string
  ): Promise<Content[]> => {
    try {
      const response = await axios({
        method: "get",
        url: `${apiUrl}/api/content/public/${content_key}`,
      });
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch content by type", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const deleteContent = async (id: number) => {
    try {
      const config = await withAuth({
        method: "delete",
        url: `${apiUrl}/api/content/${id}`,
      });
      await axios(config);
    } catch (error: any) {
      console.error("Failed to delete content", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  return (
    <ContentContext.Provider
      value={{
        createContent,
        updateContent,
        getAllContent,
        getContentByContentKey,
        deleteContent,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
