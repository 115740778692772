import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useProduct } from "../context/ProductContext";
import { Product } from "../models/Product";
import {
  Breadcrumbs,
  Button,
  IconButton,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import ProductCard from "../components/ProductCard";
import { useCategory } from "../context/CategoryContext";
import { Category } from "../models/Category";
import { toast } from "react-toastify";
import { Parameter } from "../models/Parameter";
import { useParameter } from "../context/ParameterContext";
import SearchSidePanel from "../components/SearchSidePanel";
import { animated, useSpring } from "react-spring";
import { useSearch } from "../context/SearchContext";
import { CloseOutlined } from "@mui/icons-material";
import TuneIcon from "@mui/icons-material/Tune";

const Search = () => {
  const width = window.innerWidth;

  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get("search");
  const categoryId = searchParams.get("categoryId");
  const page = searchParams.get("page");
  const minPrice = searchParams.get("minPrice");
  const maxPrice = searchParams.get("maxPrice");
  const filters = searchParams.get("filters");
  // const urlProducers = searchParams.get("producers");

  const [priceRange, setPriceRange] = React.useState<number[]>([0, 100000]);

  const [currentPrice, setCurrentPrice] = React.useState<number[]>([
    minPrice ? parseInt(minPrice) : priceRange[0],
    maxPrice ? parseInt(maxPrice) : priceRange[1],
  ]);

  const [limit, setLimit] = useState<number>(10);

  const { getProducts } = useProduct();
  const { getCategories } = useCategory();
  const { getParametersForCategoryWithValues } = useParameter();
  // const { getProducersForCategory } = useProducer();

  const [productList, setProductList] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalProducts, setTotalProducts] = useState<number>(0);

  const [categoryAncestry, setCategoryAncestry] = useState<Category[]>([]);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);

  const [parameters, setParameters] = useState<Parameter[]>([]);

  // const [producers, setProducers] = useState<Producer[]>([]);

  const fetchProducts = async () => {
    const pageNumber = page ? parseInt(page) : 1;

    try {
      const { products, totalPages, totalProducts, maxPrice, minPrice } =
        await getProducts(
          pageNumber,
          limit,
          search!,
          categoryId ? parseInt(categoryId) : null,
          currentPrice[0],
          currentPrice[1],
          JSON.parse(decodeURIComponent(filters || "[]"))
          // JSON.parse(decodeURIComponent(urlProducers || "[]"))
        );
      setProductList(products);
      setTotalPages(totalPages);
      setTotalProducts(totalProducts);
      setPriceRange([minPrice, maxPrice]);
    } catch (error) {
      toast.error("Wystąpił błąd podczas ładowania produktów");
    }
  };

  const fetchCategories = async () => {
    try {
      const { categories, ancestors, currentCategory } = await getCategories(
        parseInt(categoryId!)
      );
      setCategories(categories);
      setCategoryAncestry(ancestors);
      setCurrentCategory(currentCategory);
    } catch (error) {
      toast.error("Wystąpił błąd podczas ładowania kategorii");
    }
  };

  const fetchParameters = async () => {
    try {
      const response = await getParametersForCategoryWithValues(
        parseInt(categoryId!)
      );
      setParameters(response);
    } catch (error) {
      toast.error("Wystąpił błąd podczas ładowania parametrów");
    }
  };

  // const fetchProducers = async () => {
  //   try {
  //     const response = await getProducersForCategory(parseInt(categoryId!));
  //     setProducers(response);
  //   } catch (error) {
  //     toast.error("Wystąpił błąd podczas ładowania producentów");
  //   }
  // };

  useEffect(() => {
    fetchProducts();
    fetchParameters();
  }, [categoryId, limit, filters, minPrice, maxPrice, search]);

  useEffect(() => {
    fetchCategories();
    fetchParameters();
    // fetchProducers();
  }, [categoryId]);

  const { isSidePanelOpen, setIsSidePanelOpen } = useSearch();
  const slideStyle = useSpring({
    transform: isSidePanelOpen ? "translateX(0%)" : "translateX(-100%)",
    config: { tension: 300, friction: 40 },
  });

  const location = window.location.pathname;

  const sidePanelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidePanelRef.current &&
        !sidePanelRef.current.contains(event.target as Node)
      ) {
        setIsSidePanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidePanelOpen]);

  return (
    <div className="w-full p-2">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Button
          color="secondary"
          variant="text"
          style={{ fontSize: "small", padding: "2px" }}
          onClick={() => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete("categoryId");
            setSearchParams(newSearchParams);
          }}
        >
          Kategorie główne
        </Button>
        {categoryAncestry?.map((category) => (
          <Button
            key={category.id}
            color="primary"
            variant="text"
            style={{ fontSize: "small", padding: "2px" }}
            onClick={() => {
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("categoryId", category.id.toString());
              setSearchParams(newSearchParams);
            }}
          >
            {category.name}
          </Button>
        ))}
      </Breadcrumbs>
      <div className="flex flex-col md:grid md:grid-cols-8 md:gap-3 space-y-2 mt-5">
        <animated.div
          className={`${
            width > 768
              ? "col-span-2"
              : "h-full overflow-y-scroll w-[400px] max-w-[80vw]"
          }`}
          ref={width <= 768 ? sidePanelRef : null}
          style={
            width <= 768
              ? {
                  ...slideStyle,
                  position: "fixed",
                  padding: "1rem",
                  left: 0,
                  top: 0,
                  height: "100vh",
                  backgroundColor: "white",
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                  zIndex: 100,
                }
              : {}
          }
        >
          {width <= 768 && (
            <IconButton
              onClick={() => setIsSidePanelOpen(false)}
              style={{ marginBottom: "20px" }}
            >
              <CloseOutlined />
            </IconButton>
          )}
          <SearchSidePanel
            currentCategory={currentCategory}
            categories={categories}
            parameters={parameters}
            // producers={producers}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            // urlProducers={urlProducers}
            filters={filters}
            priceRange={priceRange}
            currentPrice={currentPrice}
            setCurrentPrice={setCurrentPrice}
          />
        </animated.div>
        <div className="col-span-6">
          <div className="flex justify-end items-start mb-4 space-x-2 divide-x-2">
            {width <= 768 && location.includes("search") && (
              <div className="flex flex-col items-center px-2">
                <p className="text-gray-700 ~text-xs/base">
                  Kategorie i filtry
                </p>
                <IconButton
                  onClick={() => setIsSidePanelOpen(!isSidePanelOpen)}
                  color="primary"
                >
                  <TuneIcon sx={{ fontSize: width <= 768 ? 25 : 40 }} />
                </IconButton>
              </div>
            )}
            <div className="flex flex-col items-center px-2">
              <p className="text-gray-700 ~text-xs/base">Limit</p>
              <Select
                id="limit"
                value={limit}
                onChange={(e) => setLimit(e.target.value as number)}
                style={{ padding: "0px 0px" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </div>
          </div>
          <p className="text-lg">
            Wyświetlono {productList ? productList.length : 0} z {totalProducts}{" "}
            produktów
          </p>
          <div className="w-full flex justify-end mb-10">
            <Pagination
              count={totalPages}
              page={page ? parseInt(page) : 1}
              onChange={() => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("page", page!);
                setSearchParams(newSearchParams);
              }}
              color="primary"
            />
          </div>
          <div
            className={`space-y-2 ${
              width <= 400 ? "flex flex-wrap items-center" : ""
            }`}
          >
            {productList.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                inDisplayMode={true}
                horizontal={width <= 400}
                fullWidth={true}
              />
            ))}
            {productList.length === 0 && (
              <p className="text-xl text-center">
                Brak produktów spełniających kryteria wyszukiwania
              </p>
            )}
          </div>
          <div className="w-full flex justify-end my-10">
            <Pagination
              count={totalPages}
              page={page ? parseInt(page) : 1}
              onChange={() => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("page", page!);
                setSearchParams(newSearchParams);
              }}
              color="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
