import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { useCategory } from "../context/CategoryContext";
import { Category } from "../models/Category";
import SearchBar from "./SearchBar";
import ShoppingList from "./ShoppingList";
import { useShoppingList } from "../context/ShoppingListContext";
import BoltIcon from "@mui/icons-material/Bolt";

import "../styles/components/navbar.css";

function NavBar() {
  const width = window.innerWidth;

  const navigate = useNavigate();

  const { getCategories } = useCategory();
  const [categories, setCategories] = useState<Category[]>([]);
  const [hideCategories, setHideCategories] = useState<boolean>(false);
  const [isShoppingListOpen, setIsShoppingListOpen] = useState(false);

  const location = window.location.pathname;

  const { shoppingList } = useShoppingList();
  const [isShaking, setIsShaking] = useState(false);

  useEffect(() => {
    if (shoppingList.length > 0) {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 300);
    }
  }, [shoppingList]);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 50) {
      setHideCategories(true);
    } else {
      setHideCategories(false);
    }
  });

  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await getCategories();
        setCategories(response.categories);
      } catch (e) {
        console.error(e);
      }
    }

    fetchCategories();
  }, []);

  return (
    <div
      className={`sticky w-full top-0 bg-white py-3 z-50 max-h-[136px] overflow-visible flex flex-col items-center p-4 ml-auto mr-auto
    ${hideCategories ? "shadow-md" : "shadow-none"}
    `}
    >
      <div className="max-w-screen-2xl w-full">
        <div className="w-full flex items-center justify-between space-x-1">
          <div className="flex items-center space-x-2">
            <Link
              to={"/"}
              className={`hover:drop-shadow-xl ${
                width <= 768 ? "w-10 h-10" : "w-16 h-16"
              }`}
            >
              <img
                src="/logo512.png"
                alt="Logo"
                className={`${width <= 768 ? "w-10 h-10" : "w-16 h-16"}`}
              />
            </Link>
          </div>
          <SearchBar />
          <div className="flex items-center space-x-2">
            <IconButton
              color={isShaking ? "success" : "primary"}
              onClick={() => setIsShoppingListOpen(!isShoppingListOpen)}
            >
              <ShoppingBagIcon sx={{ fontSize: width <= 768 ? 25 : 40 }} />
            </IconButton>
            {/* {isAuthenticated ? (
              <IconButton
                component={Link}
                to={user?.role === UserRole.Admin ? "/admin" : "/profile"}
                aria-label="profile"
                color="primary"
              >
                <Person sx={{ fontSize: width <= 768 ? 25 : 40 }} />
              </IconButton>
            ) : (
              <IconButton
                component={Link}
                to={"/login"}
                aria-label="login"
                color="primary"
              >
                <Login sx={{ fontSize: width <= 768 ? 25 : 40 }} />
              </IconButton>
            )} */}
          </div>
        </div>
        {!location.includes("/search") && width > 768 && (
          <div className="mt-2 flex w-full transition-all duration-300 space-x-2 divide-x divide-background">
            <div className="p-3 flex items-center space-x-1">
              {/* <Public /> */}
              <span>Kategorie główne</span>
            </div>
            <div className="flex items-center space-x-2 pl-2">
              {categories.map((category) => (
                <button
                  key={category.id}
                  className="hover:drop-shadow-xl nav-category-button p-3"
                  onClick={() => {
                    navigate("/search?categoryId=" + category.id);
                  }}
                >
                  <BoltIcon
                    color="secondary"
                    sx={{ fontSize: 13, marginRight: 1 }}
                  />
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        )}
        <ShoppingList
          isShoppingListOpen={isShoppingListOpen}
          setIsShoppingListOpen={setIsShoppingListOpen}
        />
      </div>
    </div>
  );
}

export default NavBar;
