import * as React from "react";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCategory } from "../context/CategoryContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Category } from "../models/Category";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function CategoryCard({
  category,
  getCategoriesData,
  showParent = false,
  inParameters = false,
}: {
  category: Category;
  getCategoriesData?: (categoryId?: number) => Promise<void>;
  showParent?: boolean;
  inParameters?: boolean;
}) {
  const { deleteCategory } = useCategory();

  const handleDeleteCategory = async () => {
    const toastId = toast.loading("Usuwanie...", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
    });

    try {
      await deleteCategory(category.id);
      toast.update(toastId, {
        render: "Kategoria usunięta!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error: Error | any) {
      console.error("Failed to delete Category", error);
      toast.update(toastId, {
        render: "Nie udało się usunąć kategorii",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleDeleteClick = async () => {
    confirmAlert({
      title: "Potwierdź usunięcie",
      message: "Czy na pewno chcesz usunąć kategorię?",
      buttons: [
        {
          label: "Tak",
          onClick: () => handleDeleteCategory(),
        },
        {
          label: "Nie",
        },
      ],
    });
  };

  return (
    <div className="shadow-sm">
      {showParent && (
        <div className="p-1">
          <div className="flex items-center space-x-2 bg-gray-100 p-2">
            {category.parentCategory ? (
              <Button
                variant="text"
                color="primary"
                size="small"
                style={{ fontSize: "small", padding: "2px" }}
                onClick={() =>
                  getCategoriesData &&
                  getCategoriesData(category.parentCategoryId!)
                }
              >
                Kategoria nadrzędna ({category.parentCategory.name})
              </Button>
            ) : (
              <Button
                variant="text"
                color="secondary"
                size="small"
                style={{ fontSize: "small", padding: "2px" }}
                onClick={() => getCategoriesData && getCategoriesData()}
              >
                Kategoria główna
              </Button>
            )}
          </div>
        </div>
      )}
      <div>
        <div
          className={`flex justify-between items-center w-full ${
            inParameters ? "h-fit px-1" : "h-16 p-1"
          }`}
        >
          <div className="flex items-center space-x-2">
            <h2 className="text-lg">{category.name}</h2>
          </div>
          <CardActions disableSpacing>
            <Button
              variant="text"
              color="primary"
              size="small"
              style={{
                fontSize: "small",
                padding: "2px",
                height: "fit-content",
                marginRight: "2px",
              }}
              onClick={() =>
                getCategoriesData && getCategoriesData(category.id)
              }
            >
              Wyświetl kategorie podrzędne
            </Button>
            {!inParameters && (
              <div>
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  endIcon={<AddIcon />}
                  style={{ fontSize: "small", padding: "6px" }}
                  component={Link}
                  to={`add/${category.id}`}
                >
                  Dodaj kategorie podrzędną
                </Button>
                <IconButton
                  aria-label="edit"
                  component={Link}
                  to={`/admin/categories/edit/${category.id}`}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="warning"
                  onClick={handleDeleteClick}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </CardActions>
        </div>
      </div>
    </div>
  );
}
