import React from "react";
import { useContent } from "../context/ContentContext";

function TermsAndConditions() {
  const [regulamin, setRegulamin] = React.useState("");
  const { getContentByContentKey } = useContent();

  React.useEffect(() => {
    const getTerms = async () => {
      const terms = await getContentByContentKey("terms_and_conditions");
      if (terms.length > 0) {
        setRegulamin(terms[0].content_data);
      }
    };

    getTerms();
  }, []);

  return (
    <div>
      <h1 className="text-5xl font-bold">Regulamin, Polityka Prywatności</h1>
      <br />
      <br />
      {regulamin ? (
        <div dangerouslySetInnerHTML={{ __html: regulamin }} />
      ) : (
        <p className="text-gray-500">Brak regulaminu</p>
      )}
    </div>
  );
}

export default TermsAndConditions;
