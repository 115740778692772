import * as React from "react";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useProducer } from "../context/ProducerContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";

type CardProps = {
  id: number;
  name: string;
  description?: string;
  image?: string;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProducerCard(props: CardProps) {
  const [expanded, setExpanded] = React.useState(false);

  const { deleteProducer } = useProducer();

  const handleDeleteProducer = async () => {
    const toastId = toast.loading("Dodawanie...", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
    });

    try {
      await deleteProducer(props.id);
      toast.update(toastId, {
        render: "Producent usunięty!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error: Error | any) {
      console.error("Failed to delete producer", error);
      toast.update(toastId, {
        render: "Nie udało się usunąć producenta",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleDeleteClick = async () => {
    confirmAlert({
      title: "Potwierdź usunięcie",
      message: "Czy na pewno chcesz usunąć producenta?",
      buttons: [
        {
          label: "Tak",
          onClick: () => handleDeleteProducer(),
        },
        {
          label: "Nie",
        },
      ],
    });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="shadow-sm">
      <div className="flex h-32 justify-between w-full p-1">
        <div className="flex items-center space-x-2">
          <div className="overflow-hidden rounded-sm h-32 w-32 flex items-center justify-center">
            <img
              src={props.image ? props.image : "https://placehold.co/125x125"}
              alt={props.name}
              height={125}
              width={125}
              className="object-cover object-center"
            />
          </div>
          <h2 className="text-lg">{props.name}</h2>
        </div>
        <CardActions disableSpacing>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <IconButton
            aria-label="edit"
            component={Link}
            to={`/admin/producers/edit/${props.id}`}
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="warning"
            onClick={handleDeleteClick}
          >
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            {props.description ? props.description : "Brak opisu"}
          </Typography>
        </CardContent>
      </Collapse>
    </div>
  );
}
