import React from "react";
import ProducerForm from "../../../forms/ProducerForm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

function AddProducer() {
  return (
    <div>
      <IconButton component={Link} to="/admin/producers">
        <ArrowBackIcon />
      </IconButton>
      <ProducerForm />
    </div>
  );
}

export default AddProducer;
