import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface PrivateRouteProps {
  children: ReactElement;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const navigate = useNavigate();

  const storedToken = localStorage.getItem("token");
  const tokenExpiration = localStorage.getItem("tokenExpiration");

  useEffect(() => {
    if (!storedToken) {
      navigate("/login");
    }

    if (storedToken && tokenExpiration) {
      const now = new Date();
      const expirationDate = new Date(tokenExpiration);

      if (now >= expirationDate) {
        navigate("/login");
      }
    }
  }, []);

  if (
    !storedToken ||
    (storedToken && tokenExpiration && new Date() >= new Date(tokenExpiration))
  ) {
    return null;
  }

  return children;
}

export default PrivateRoute;
