import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import ProductForm from "../../../forms/ProductForm";
import { useProduct } from "../../../context/ProductContext";
import { Product } from "../../../models/Product";
import { toast } from "react-toastify";

function EditProduct() {
  const { id } = useParams<{ id: string }>();

  const { getProduct } = useProduct();
  const [product, setProduct] = React.useState<Product | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await getProduct(Number(id));
        setProduct(response.product);
      } catch (error: Error | any) {
        toast.error("Błąd podczas pobierania producenta");
        navigate("/admin/producers");
      }
    };
    fetchProduct();
  }, [id]);

  return (
    <div>
      <IconButton component={Link} to="/admin/products">
        <ArrowBack />
      </IconButton>
      {product && <ProductForm product={product} inEditMode={true} />}
    </div>
  );
}

export default EditProduct;
