import { Controller } from "react-hook-form";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useCategory } from "../../context/CategoryContext";
import useDebounce from "../../hooks/useDebounce";
import { Category } from "../../models/Category";
import { useParameter } from "../../context/ParameterContext";

const CategorySelect = ({
  control,
  errors,
  setCategoryId,
}: {
  control: any;
  errors: any;
  setCategoryId: any;
}) => {
  const [categories, setCategories] = useState<Category[]>([]);

  const { searchCategories } = useCategory();
  const { getParametersForCategory } = useParameter();

  const [inputValue, setInputValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const debouncedQuery = useDebounce(inputValue, 1000);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      const response = await searchCategories(1, 10, debouncedQuery);
      setCategories(response.categories);
      setLoading(false);
    };

    if (debouncedQuery || debouncedQuery === "") {
      fetchCategories();
    }
  }, [debouncedQuery]);

  return (
    <Controller
      name="categoryId"
      control={control}
      rules={{ required: "Kategoria jest wymagana" }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          value={selectedCategory}
          inputValue={inputValue}
          options={categories}
          loading={loading}
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            setSelectedCategory(null);
          }}
          onChange={(event, newValue) => {
            setSelectedCategory(newValue);
            setInputValue(newValue ? newValue.name : "");
            field.onChange(newValue ? newValue.id : "");
            getParametersForCategory(newValue ? newValue.id : null);
            setCategoryId(newValue ? newValue.id : null);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Kategoria"
              variant="outlined"
              error={!!errors.categoryId}
              helperText={
                errors.categoryId ? String(errors.categoryId.message) : ""
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default CategorySelect;
