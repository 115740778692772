import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CategoryForm from "../../../forms/CategoryForm";
import { useCategory } from "../../../context/CategoryContext";
import { Category } from "../../../models/Category";
import { toast } from "react-toastify";

function AddCategory() {
  const { id } = useParams<{ id: string }>();
  const { getCategory } = useCategory();
  const [category, setCategory] = React.useState<Category | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchCategory() {
      if (id) {
        try {
          const category = await getCategory(parseInt(id));
          setCategory(category);
        } catch (error: any) {
          toast.error("Kategoria nie istnieje");
          navigate("/admin/categories");
        }
      }
    }

    fetchCategory();
  }, [id]);

  return (
    <div>
      <IconButton component={Link} to="/admin/categories">
        <ArrowBackIcon />
      </IconButton>
      {category && (
        <CategoryForm
          id={category?.id}
          name={category?.name}
          inEditMode={true}
        />
      )}
    </div>
  );
}

export default AddCategory;
