import React, { createContext, useContext, ReactNode } from "react";
import axios from "axios";
import { withAuth } from "../middleware/withAuth";
import { Inquiry, InquiryStatus } from "../models/Inquiry";

interface GetInquiriesParams {
  search?: string;
  status?: InquiryStatus | null;
  sort?: "newest" | "oldest";
  page?: number;
  limit?: number;
}

interface InquiryContextType {
  createInquiry: (
    name: string,
    email: string,
    products: { productId: number; quantity: number }[],
    nip?: string,
    remarks?: string
  ) => Promise<void>;
  getInquiries: (params: GetInquiriesParams) => Promise<{
    inquiries: Inquiry[];
    currentPage: number;
    totalPages: number;
    totalInquiries: number;
  }>;
  getInquiry: (inquiryId: number) => Promise<Inquiry>;
  changeInquiryStatus: (
    inquiryId: number,
    status: InquiryStatus
  ) => Promise<void>;
}

const InquiryContext = createContext<InquiryContextType | undefined>(undefined);

export const useInquiry = () => {
  const context = useContext(InquiryContext);
  if (!context) {
    throw new Error("useInquiry must be used within a InquiryProvider");
  }
  return context;
};

interface InquiryProviderProps {
  children: ReactNode;
}

export const InquiryProvider: React.FC<InquiryProviderProps> = ({
  children,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const createInquiry = async (
    name: string,
    email: string,
    products: { productId: number; quantity: number }[],
    nip?: string,
    remarks?: string
  ) => {
    try {
      const config = {
        method: "post",
        url: `${apiUrl}/api/inquiries`,
        data: {
          name,
          email,
          products,
          nip,
          remarks,
        },
      };
      await axios(config);
    } catch (error: any) {
      console.error("Failed to create Inquiry", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getInquiries = async ({
    search,
    status,
    sort,
    page = 1,
    limit = 10,
  }: GetInquiriesParams): Promise<{
    inquiries: Inquiry[];
    currentPage: number;
    totalPages: number;
    totalInquiries: number;
  }> => {
    try {
      if (page < 1 || limit < 1) {
        throw new Error("Invalid pagination parameters");
      }

      const config = await withAuth({
        method: "get",
        url: `${apiUrl}/api/inquiries`,
        params: {
          search,
          status,
          sort,
          page,
          limit,
        },
      });

      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch inquiries", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getInquiry = async (inquiryId: number): Promise<Inquiry> => {
    try {
      const config = await withAuth({
        method: "get",
        url: `${apiUrl}/api/inquiries/${inquiryId}`,
      });
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch inquiry", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const changeInquiryStatus = async (
    inquiryId: number,
    status: InquiryStatus
  ) => {
    try {
      const config = await withAuth({
        method: "post",
        url: `${apiUrl}/api/inquiries/status/${inquiryId}`,
        data: {
          status,
        },
      });
      await axios(config);
    } catch (error: any) {
      console.error("Failed to change inquiry status", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  return (
    <InquiryContext.Provider
      value={{
        createInquiry,
        getInquiries,
        getInquiry,
        changeInquiryStatus,
      }}
    >
      {children}
    </InquiryContext.Provider>
  );
};
