import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useInquiry } from "../context/InquiryContext";

type Props = {
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  products: { productId: number; quantity: number }[];
  singleProduct?: boolean;
};

type FormData = {
  name: string;
  nip: string;
  email: string;
  remarks: string;
  rulesAccepted: boolean;
  quantity: number;
};

function InquiryForm({
  isDialogOpen,
  setIsDialogOpen,
  products,
  singleProduct,
}: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      nip: "",
      email: "",
      remarks: "",
      quantity: 1,
      rulesAccepted: false,
    },
  });

  const { createInquiry } = useInquiry();

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const onSubmit = async (data: FormData) => {
    const toastId = toast.loading("Wysyłanie zapytania...");
    try {
      const inquiryConfig = {
        name: data.name,
        email: data.email,
        products: products,
        nip: data.nip,
        remarks: data.remarks,
      };

      if (singleProduct) {
        inquiryConfig.products[0].quantity = data.quantity;
      }

      await createInquiry(
        data.name,
        data.email,
        products,
        data.nip,
        data.remarks
      );
      toast.update(toastId, {
        render: "Zapytanie zostało złożone!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error: any) {
      console.error("Failed to process inquiry", error);
      toast.update(toastId, {
        render: "Nie udało się złożyć zapytania",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
    handleDialogClose();
    reset();
  };

  return (
    <div>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <h2 className="text-center m-2 text-xl font-bold">
          Zapytanie o produkt{singleProduct ? "" : "y"}
        </h2>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Imię i Nazwisko / Firma jest wymagane" }}
              render={({ field }) => (
                <TextField
                  margin="dense"
                  label="Imię i Nazwisko / Firma"
                  fullWidth
                  {...field}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                />
              )}
            />
            <Controller
              name="nip"
              control={control}
              render={({ field }) => (
                <TextField margin="dense" label="NIP" fullWidth {...field} />
              )}
            />
            <p className="text-sm text-gray-500">
              Podaj NIP, jeśli chcesz otrzymać ofertę dla firmy.
            </p>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email jest wymagany",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Niepoprawny format adresu email",
                },
              }}
              render={({ field }) => (
                <TextField
                  margin="dense"
                  label="Email"
                  type="email"
                  fullWidth
                  {...field}
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                />
              )}
            />
            {singleProduct && (
              <Controller
                name="quantity"
                control={control}
                rules={{ required: "Ilość jest wymagana", min: 1 }}
                render={({ field }) => (
                  <TextField
                    margin="dense"
                    label="Ilość"
                    type="number"
                    fullWidth
                    {...field}
                    error={!!errors.quantity}
                    helperText={errors.quantity ? errors.quantity.message : ""}
                  />
                )}
              />
            )}
            <Controller
              name="remarks"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="dense"
                  label="Uwagi"
                  fullWidth
                  multiline
                  rows={4}
                  {...field}
                />
              )}
            />
            <Controller
              name="rulesAccepted"
              control={control}
              rules={{ required: "Musisz zaakceptować zasady" }}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Wyrażam zgodę na przetwarzanie danych osobowych w celu realizacji zapytania."
                  style={{ userSelect: "none", fontSize: "0.8rem" }}
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleDialogClose}
            color="secondary"
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Złóż zapytanie
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InquiryForm;
