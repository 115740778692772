import React, { useEffect, useState } from "react";
import { Button, Pagination, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useProducer } from "../../../context/ProducerContext";
import ProducerCard from "../../../components/ProducerCard";
import useDebounce from "../../../hooks/useDebounce";
import { Producer } from "../../../models/Producer";

function Producers() {
  const { control } = useForm();
  const [query, setQuery] = React.useState("");

  const [producers, setProducers] = useState<Producer[]>([]);
  const [totalProducers, setTotalProducers] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { getProducers } = useProducer();
  const debouncedQuery = useDebounce(query, 500);

  const getProducersData = async (page: number, query: string) => {
    const response = await getProducers(page, 5, query);
    setProducers(response.producers);
    setTotalProducers(response.totalProducers);
    setTotalPages(response.totalPages);
    setCurrentPage(response.currentPage);
  };

  useEffect(() => {
    getProducersData(currentPage, debouncedQuery);
  }, [debouncedQuery]);

  return (
    <div className="flex flex-col items-stretch space-y-5">
      <div className="flex items-center space-x-2">
        <Button
          variant="outlined"
          color="success"
          size="small"
          endIcon={<AddIcon />}
          style={{ fontSize: "small", padding: "6px" }}
          component={Link}
          to="add"
        >
          Dodaj producenta
        </Button>
      </div>
      <div className="space-y-2">
        <h3 className="text-2xl">Lista producentów</h3>
        <Controller
          name="search"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Szukaj producenta"
              variant="outlined"
              size="small"
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          )}
        />
        <p>
          Wyświetlono {producers.length} z {totalProducers} producentów
        </p>
        <ul className="space-y-2 p-2">
          {producers.map((producer) => (
            <ProducerCard
              key={producer.id}
              id={producer.id}
              name={producer.name}
              description={producer.description!}
              image={producer.image!}
            />
          ))}
        </ul>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, page) => getProducersData(page, query)}
          color="primary"
        />
      </div>
    </div>
  );
}

export default Producers;
