import React, { useEffect, useState } from "react";
import { Button, Pagination, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useProduct } from "../../../context/ProductContext";
import useDebounce from "../../../hooks/useDebounce";
import ProductCard from "../../../components/ProductCard";
import { Product } from "../../../models/Product";

function Products() {
  const { control } = useForm();
  const [query, setQuery] = React.useState("");

  const [products, setProducts] = useState<Product[]>([]);
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { getProducts } = useProduct();
  const debouncedQuery = useDebounce(query, 500);

  const getProductsData = async (page: number, query: string) => {
    const response = await getProducts(page, 10, query);
    setProducts(response.products);
    setTotalProducts(response.totalProducts);
    setTotalPages(response.totalPages);
    setCurrentPage(response.currentPage);
  };

  useEffect(() => {
    getProductsData(currentPage, debouncedQuery);
  }, [debouncedQuery]);

  return (
    <div className="flex flex-col items-stretch space-y-5">
      <div className="flex items-center space-x-2">
        <Button
          variant="outlined"
          color="success"
          size="small"
          endIcon={<AddIcon />}
          style={{ fontSize: "small", padding: "6px" }}
          component={Link}
          to="add"
        >
          Dodaj produkt
        </Button>
      </div>
      <div className="space-y-2">
        <h3 className="text-2xl">Lista producentów</h3>
        <Controller
          name="search"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Szukaj produktu"
              variant="outlined"
              size="small"
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          )}
        />
        <p>
          Wyświetlono {products ? products.length : 0} z {totalProducts}{" "}
          produktów
        </p>
        <ul className="space-y-2 p-2">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              inDisplayMode={false}
            />
          ))}
        </ul>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, page) => getProductsData(page, query)}
          color="primary"
        />
      </div>
    </div>
  );
}

export default Products;
