import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProducer } from "../../../context/ProducerContext";
import { Producer } from "../../../models/Producer";
import ProducerForm from "../../../forms/ProducerForm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";

function EditProducer() {
  const { id } = useParams();
  const { getProducer } = useProducer();
  const [producer, setProducer] = useState<Producer | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducer = async () => {
      try {
        const producer = await getProducer(Number(id));
        setProducer(producer);
      } catch (error: Error | any) {
        toast.error("Błąd podczas pobierania producenta");
        navigate("/admin/producers");
      }
    };
    fetchProducer();
  }, [id]);

  return (
    <div>
      <IconButton component={Link} to="/admin/producers">
        <ArrowBackIcon />
      </IconButton>
      {producer ? (
        <ProducerForm
          id={producer.id}
          name={producer.name}
          description={producer.description!}
          image={producer.image!}
          inEditMode={true}
        />
      ) : (
        <p>Ładowanie...</p>
      )}
    </div>
  );
}

export default EditProducer;
