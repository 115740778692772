import React, { useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useContent } from "../../../context/ContentContext";
import { toast } from "react-toastify";
import { ContentType } from "../../../models/Content";
import { Content as ContentInterface } from "../../../models/Content";
import CustomTabPanel from "../../../components/CustomTabPanel";
import Terms from "./Terms";
import Banners from "./Banners";

function Content() {
  const tokenExpiration = localStorage.getItem("tokenExpiration");
  const user = localStorage.getItem("user");

  const { createContent, getAllContent, updateContent } = useContent();

  const [data, setData] = React.useState<ContentInterface[]>([]);

  const [regulamin, setRegulamin] = React.useState("");

  useEffect(() => {
    const getData = async () => {
      const terms = await getAllContent();
      const regulaminContent = terms.find(
        (content) => content.content_key === "terms_and_conditions"
      );
      if (regulaminContent) {
        setRegulamin(regulaminContent.content_data);
      }

      setData(terms);
    };

    getData();
  }, []);

  const saveTerms = async () => {
    const toastId = toast.loading("Zapisywanie regulaminu...");
    try {
      if (
        data.find((content) => content.content_key === "terms_and_conditions")
      ) {
        await updateContent(
          data.find(
            (content) => content.content_key === "terms_and_conditions"
          )!.id,
          "terms_and_conditions",
          ContentType.TEXT,
          regulamin
        );
      } else {
        await createContent(
          "terms_and_conditions",
          ContentType.TEXT,
          regulamin
        );
      }
      toast.update(toastId, {
        render: "Regulamin został zapisany",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error: any) {
      console.error("Failed to save terms", error);
      toast.update(toastId, {
        render: "Wystąpił błąd podczas zapisywania regulaminu",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [panel, setPanel] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanel(newValue);
  };

  return (
    <div>
      <h1 className="text-3xl font-bold">Panel główny</h1>
      <p className="text-gray-500">Witaj w panelu administracyjnym</p>
      <br />
      <p>
        <strong>Użytkownik:</strong> {JSON.parse(user!).email}
      </p>
      <p>
        <strong>Data wygaśnięcia sesji:</strong>{" "}
        {new Date(tokenExpiration!).toLocaleString("pl-PL")}
      </p>
      <br />
      <p className="text-gray-500">
        Edycja zawartości strony głównej sklepu internetowego
      </p>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={panel}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Regulamin" {...a11yProps(0)} />
          <Tab label="Banery reklamowe" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={panel} index={0}>
        <Terms
          terms={regulamin}
          setTerms={setRegulamin}
          saveTerms={saveTerms}
        />
      </CustomTabPanel>
      <CustomTabPanel value={panel} index={1}>
        <Banners />
      </CustomTabPanel>
    </div>
  );
}

export default Content;
