import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Inquiry } from "../models/Inquiry";
import { SendOfferRequestBody, useOffer } from "../context/OfferContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

enum DeliveryTime {
  "1-3 dni",
  "3-5 dni",
  "5-7 dni",
  "7-14 dni",
  "14+ dni",
}

type Props = {
  inquiry: Inquiry;
  offerDetails?: string;
  validUntil?: string;
  productPrices?: {
    id: number;
    price: number;
    quantity: number;
    deliveryTime: DeliveryTime;
    removeProductFromOffer?: boolean;
  }[];
};

type FormData = {
  offerDetails?: string;
  validUntil?: string;
  productPrices: {
    id: number;
    price: number;
    quantity: number;
    deliveryTime: DeliveryTime;
    removeProductFromOffer?: boolean;
  }[];
};

const deliveryTimeOptions = [
  { label: "1-3 dni", value: DeliveryTime["1-3 dni"] },
  { label: "3-5 dni", value: DeliveryTime["3-5 dni"] },
  { label: "5-7 dni", value: DeliveryTime["5-7 dni"] },
  { label: "7-14 dni", value: DeliveryTime["7-14 dni"] },
  { label: "14+ dni", value: DeliveryTime["14+ dni"] },
];

function OfferForm({ inquiry }: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    defaultValues: {},
  });

  useEffect(() => {
    if (inquiry.products && inquiry?.products?.length > 0) {
      const initializedPrices = inquiry.products.map((product) => ({
        id: product.productId,
        price: product.product?.price || 0,
        quantity: product.quantity,
        deliveryTime: DeliveryTime["1-3 dni"],
        removeProductFromOffer: false,
      }));
      setValue("productPrices", initializedPrices);
    }
  }, [inquiry, setValue]);

  const { sendOffer } = useOffer();
  const navigate = useNavigate();

  const onSubmit = async (data: FormData) => {
    const requestBody: SendOfferRequestBody = {
      inquiryId: inquiry.id,
      offerDetails: data.offerDetails || "",
      validUntil: data.validUntil || new Date().toISOString().split("T")[0],
      products: data.productPrices.map((product) => ({
        id: product.id,
        price: product.price,
        quantity: product.quantity,
        removeProductFromOffer: product.removeProductFromOffer,
      })),
    };

    const toastId = toast.loading("Wysyłanie oferty...");
    try {
      await sendOffer(requestBody);
      toast.update(toastId, {
        render: "Oferta została wysłana!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      navigate("/admin/offers");
    } catch (error: any) {
      toast.update(toastId, {
        render: "Nie udało się wysłać oferty",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      console.error("Failed to send offer", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4">
      <Controller
        name="offerDetails"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Dodatkowe informacje o ofercie"
            fullWidth
            error={!!errors.offerDetails}
            helperText={errors.offerDetails ? errors.offerDetails.message : ""}
          />
        )}
      />

      <Controller
        name="validUntil"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="date"
            label="Ważne do"
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={!!errors.validUntil}
            helperText={errors.validUntil ? errors.validUntil.message : ""}
          />
        )}
      />

      <p className="text-xl">Ceny produktów</p>
      <p className="text-sm text-gray-500">
        Ustaw ceny produktów i czasy dostawy. Zaznaczenie pola "Usuń produkt z
        oferty" spowoduje usunięcie produktu z oferty, nie musisz usuwać ceny
        ani czasu dostawy.
      </p>
      {inquiry.products?.map((product, index) => (
        <div key={product.productId} className="flex flex-col space-y-2">
          <div className="flex items-center space-x-4">
            <p className="text-base">Ilość: {product.quantity}</p>
            <img
              src={product.product?.primaryImage || "/placeholder.jpg"}
              alt={product.product?.name}
              width={50}
              height={50}
            />
            <Typography variant="h6">{product.product?.name}</Typography>
          </div>

          <div className="flex items-center justify-evenly space-x-2">
            <Controller
              name={`productPrices.${index}.price`}
              control={control}
              rules={{ required: "Price is required", min: 0 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Cena za sztukę"
                  defaultValue={product.product?.price}
                  fullWidth
                  error={!!errors.productPrices?.[index]?.price}
                  helperText={
                    errors.productPrices?.[index]?.price
                      ? "Price is required"
                      : ""
                  }
                />
              )}
            />

            <Controller
              name={`productPrices.${index}.deliveryTime`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Czas dostawy produktu"
                  defaultValue={DeliveryTime["1-3 dni"]}
                  fullWidth
                  error={!!errors.productPrices?.[index]?.deliveryTime}
                  helperText={
                    errors.productPrices?.[index]?.deliveryTime
                      ? "Delivery time is required"
                      : ""
                  }
                >
                  {deliveryTimeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <div className="w-1/2">
              <Controller
                name={`productPrices.${index}.removeProductFromOffer`}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    style={{ padding: "0" }}
                    control={<Checkbox {...field} size="small" />}
                    label="Usuń produkt z oferty"
                  />
                )}
              />
            </div>
          </div>
        </div>
      ))}

      <Button type="submit" variant="contained" color="primary">
        Złóż ofertę
      </Button>
    </form>
  );
}

export default OfferForm;
