import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="w-full flex flex-col items-center justify-center h-full mt-[10vh]">
      <div className="flex items-center space-x-2">
        <h1 className="text-6xl font-bold">(404)</h1>
      </div>

      <h2 className="text-3xl font-bold">
        Nie znaleziono strony której szukasz
      </h2>
      <br />
      <Link
        to="/"
        className="text-secondary hover:underline hover:text-primary text-2xl"
      >
        Wróć do strony głównej
      </Link>
    </div>
  );
}

export default NotFound;
