import { AxiosRequestConfig } from "axios";

export const withAuth = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const token = localStorage.getItem("token");

  if (!token) {
    throw new Error("No authentication token found");
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
};
