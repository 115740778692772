import { Close } from "@mui/icons-material";
import { Button, InputLabel } from "@mui/material";
import React, { useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { SecondaryImage } from "../../models/Product";

type Props = {
  control: any;
  watch: any;
  setValue: any;
  secondaryImages: SecondaryImage[];
  setRemovedImages: React.Dispatch<React.SetStateAction<Set<number>>>;
};

function SecondaryImages({
  control,
  watch,
  setValue,
  secondaryImages,
  setRemovedImages,
}: Props) {
  const { fields, append } = useFieldArray({
    control,
    name: "secondaryImages",
  });
  const [inEditModeSecondaryImages, setInEditModeSecondaryImages] =
    useState(secondaryImages);

  const handleFileChange = (index: number, file: File | null) => {
    const updatedFiles = [...watch("secondaryImages")];
    if (file) {
      updatedFiles[index] = file;
    } else {
      updatedFiles.splice(index, 1);
    }
    setValue("secondaryImages", updatedFiles);
  };

  const handleRemoveNewImage = (index: number) => {
    const currentImages = watch("secondaryImages");
    const updatedImages = [...currentImages];

    updatedImages.splice(index, 1);
    setValue("secondaryImages", updatedImages);
  };

  const handleRemoveExistingImage = (id: number) => {
    setInEditModeSecondaryImages((prev) =>
      prev.filter((image) => image.id !== id)
    );
    setRemovedImages((prev) => new Set(prev.add(id)));
  };

  return (
    <>
      <div>
        <p className="text-lg">Dodatkowe zdjęcia</p>
        {inEditModeSecondaryImages.length > 0 &&
          inEditModeSecondaryImages.map((image, index) => (
            <div className="flex items-center space-x-2">
              <img
                key={index}
                src={image.image}
                alt={`Secondary image${index + 1}`}
                style={{
                  maxWidth: "100px",
                  marginTop: "8px",
                }}
              />
              <Button
                variant="text"
                size="small"
                color="error"
                endIcon={<Close />}
                style={{ padding: 0, fontSize: "small" }}
                onClick={() => handleRemoveExistingImage(image.id)}
              >
                Usuń zdjęcie
              </Button>
            </div>
          ))}
        {fields.map((field, index) => (
          <div className="mt-2 p-2 border-2">
            <Controller
              name={`secondaryImages.${index}`}
              control={control}
              render={() => (
                <div>
                  <InputLabel htmlFor={`secondary-image-${index}`}>
                    Dodaj dodatkowe zdjęcie {index + 1}
                  </InputLabel>
                  <input
                    id={`secondary-image-${index}`}
                    type="file"
                    accept="image/*"
                    name="secondaryImages"
                    onChange={(e) =>
                      handleFileChange(index, e.target.files?.[0] || null)
                    }
                    style={{ marginTop: "8px" }}
                  />
                </div>
              )}
            />

            <Button
              variant="text"
              size="small"
              color="error"
              endIcon={<Close />}
              style={{ padding: 0, fontSize: "small" }}
              onClick={() => handleRemoveNewImage(index)}
            >
              Usuń zdjęcie
            </Button>
          </div>
        ))}
      </div>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          append({ id: Date.now().toString(), image: null });
          setValue("secondaryImagesOrders", [
            ...watch("secondaryImagesOrders"),
            fields.length,
          ]);
        }}
      >
        Dodaj dodatkowe zdjęcie
      </Button>
    </>
  );
}

export default SecondaryImages;
