import React, { createContext, useContext, useState, ReactNode } from "react";
import axios from "axios";
import { withAuth } from "../middleware/withAuth";
import { Parameter } from "../models/Parameter";

interface ParameterContextType {
  createParameter: (
    name: string,
    description?: string,
    categoryId?: number
  ) => Promise<void>;
  editParameter: (
    id: number,
    name?: string,
    description?: string,
    categoryId?: number
  ) => Promise<void>;
  getParameters: (
    page?: number,
    limit?: number,
    query?: string
  ) => Promise<{
    parameters: Parameter[];
    totalParameters: number;
    totalPages: number;
    currentPage: number;
  }>;
  getParametersForSingleCategory: (
    categoryId: number | null
  ) => Promise<Parameter[]>;
  getParametersForCategory: (categoryId: number | null) => Promise<Parameter[]>;
  getParameter: (id: number) => Promise<Parameter>;
  deleteParameter: (id: number) => Promise<void>;
  getParametersForCategoryWithValues: (
    categoryId: number | null
  ) => Promise<Parameter[]>;
}

const ParameterContext = createContext<ParameterContextType | undefined>(
  undefined
);

export const useParameter = () => {
  const context = useContext(ParameterContext);
  if (!context) {
    throw new Error("useParameter must be used within a ParameterProvider");
  }
  return context;
};

interface ParameterProviderProps {
  children: ReactNode;
}

export const ParameterProvider: React.FC<ParameterProviderProps> = ({
  children,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const getParametersForCategoryWithValues = async (
    categoryId: number | null
  ): Promise<Parameter[]> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/parameters/category/values/${
          categoryId ? categoryId : ""
        }`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch parameters", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const createParameter = async (
    name: string,
    description?: string,
    categoryId?: number | null
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("name", name);
    if (description) formData.append("description", description);
    if (categoryId) formData.append("categoryId", categoryId.toString());

    try {
      const config = await withAuth({
        method: "post",
        url: `${apiUrl}/api/parameters/parameter`,
        headers: { "Content-Type": "application/json" },
        data: formData,
      });
      await axios(config);
    } catch (error: any) {
      console.error("Failed to create Parameter", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const editParameter = async (
    id: number,
    name?: string,
    description?: string,
    categoryId?: number
  ): Promise<void> => {
    const formData = new FormData();
    if (name) formData.append("name", name);
    if (description) formData.append("description", description);
    if (categoryId) formData.append("categoryId", categoryId.toString());

    try {
      const config = await withAuth({
        method: "put",
        url: `${apiUrl}/api/parameters/parameter/${id}`,
        headers: { "Content-Type": "application/json" },
        data: formData,
      });
      await axios(config);
    } catch (error: any) {
      console.error("Failed to edit Parameter", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const deleteParameter = async (id: number): Promise<void> => {
    try {
      const config = await withAuth({
        method: "delete",
        url: `${apiUrl}/api/parameters/parameter/${id}`,
      });
      await axios(config);
      await getParametersForSingleCategory(null);
    } catch (error: any) {
      console.error("Failed to delete Parameter", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getParametersForSingleCategory = async (
    categoryId: number | null
  ): Promise<Parameter[]> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/parameters/single/${
          categoryId ? categoryId : ""
        }`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch Parameters", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getParameter = async (id: number): Promise<Parameter> => {
    try {
      const config = await withAuth({
        method: "get",
        url: `${apiUrl}/api/parameters/parameter/${id}`,
      });
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch Parameter", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getParameters = async (
    page?: number,
    limit?: number,
    query?: string
  ): Promise<{
    parameters: Parameter[];
    totalParameters: number;
    totalPages: number;
    currentPage: number;
  }> => {
    try {
      const config = await withAuth({
        method: "get",
        url: `${apiUrl}/api/parameters`,
        params: { page, limit, search: query },
      });
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch parameters", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getParametersForCategory = async (
    categoryId: number | null
  ): Promise<Parameter[]> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/parameters/category/${
          categoryId ? categoryId : ""
        }`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch parameters", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  return (
    <ParameterContext.Provider
      value={{
        getParametersForSingleCategory,
        createParameter,
        editParameter,
        deleteParameter,
        getParameter,
        getParameters,
        getParametersForCategory,
        getParametersForCategoryWithValues,
      }}
    >
      {children}
    </ParameterContext.Provider>
  );
};
