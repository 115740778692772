import React from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCategory } from "../context/CategoryContext";

interface CategoryFormData {
  name: string;
}

interface CategoryFormProps {
  parentCategoryId?: number;
  id?: number;
  name?: string;
  inEditMode?: boolean;
}

function CategoryForm({
  parentCategoryId,
  id,
  name = "",
  inEditMode = false,
}: CategoryFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CategoryFormData>({
    defaultValues: {
      name,
    },
  });

  const { createCategory, editCategory } = useCategory();
  const navigate = useNavigate();

  const onSubmit = async (data: CategoryFormData) => {
    const toastId = toast.loading(
      inEditMode ? "Edytowanie..." : "Dodawanie...",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      }
    );

    try {
      const formData = new FormData();
      formData.append("name", data.name);

      if (inEditMode && id) {
        await editCategory(id, data.name);
        toast.update(toastId, {
          render: "Kategoria edytowana!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        await createCategory(data.name, parentCategoryId);
        toast.update(toastId, {
          render: "Kategoria dodana!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }

      navigate("/admin/categories");
    } catch (error: Error | any) {
      toast.update(toastId, {
        render: inEditMode
          ? "Nie udało się edytować kategorii"
          : "Nie udało się dodać kategorii",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col space-y-2 items-stretch w-full"
    >
      <Controller
        name="name"
        control={control}
        defaultValue={name}
        rules={{ required: "Nazwa jest wymagana" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Nazwa"
            error={!!errors.name}
            helperText={errors.name ? String(errors.name.message) : ""}
          />
        )}
      />
      <Button type="submit" variant="contained" color="primary">
        {inEditMode ? "Zaktualizuj kategorię" : "Utwórz kategorię"}
      </Button>
    </form>
  );
}

export default CategoryForm;
