import React, { useEffect, useState } from "react";
import { Breadcrumbs, Button, Pagination, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useCategory } from "../../../context/CategoryContext";
import CategoryCard from "../../../components/CategoryCard";
import { useParameter } from "../../../context/ParameterContext";
import ParameterCard from "../../../components/ParameterCard";
import useDebounce from "../../../hooks/useDebounce";
import { Category } from "../../../models/Category";
import { Parameter } from "../../../models/Parameter";

function Parameters() {
  const { control } = useForm();
  const [query, setQuery] = React.useState("");

  const [categories, setCategories] = useState<Category[]>([]);
  const [categoryAncestors, setCategoryAncestors] = useState<Category[]>([]);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

  const { getCategories } = useCategory();

  const [parameters, setParameters] = useState<Parameter[]>([]);
  const [totalParameters, setTotalParameters] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [usingSearch, setUsingSearch] = useState<boolean>(false);

  const { getParametersForSingleCategory, getParameters } = useParameter();

  const debouncedQuery = useDebounce(query, 500);

  const getParametersData = async (page: number, query: string) => {
    const response = await getParameters(page, 10, query);
    setParameters(response.parameters);
    setTotalParameters(response.totalParameters);
    setTotalPages(response.totalPages);
    setCurrentPage(response.currentPage);
    setUsingSearch(true);
  };

  useEffect(() => {
    if (debouncedQuery !== "") {
      getParametersData(currentPage, debouncedQuery);
      setUsingSearch(true);
    }
  }, [debouncedQuery]);

  useEffect(() => {
    fetchParametersDataForCategory(currentCategory?.id!);
    setUsingSearch(false);
  }, [currentCategory]);

  const fetchParametersDataForCategory = async (categoryId: number) => {
    const response = await getParametersForSingleCategory(categoryId);
    setParameters(response);
  }

  const fetchCategories = async (categoryId?: number) => {
    const response = await getCategories(categoryId!);
    setCategories(response.categories);
    setCategoryAncestors(response.ancestors);
    setCurrentCategory(response.currentCategory);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="flex flex-col items-stretch space-y-5">
      <div className="flex items-center space-x-2">
        <Button
          variant="outlined"
          color="success"
          size="small"
          endIcon={<AddIcon />}
          style={{ fontSize: "small", padding: "6px" }}
          component={Link}
          to="add"
        >
          Dodaj parametr globalny
        </Button>
      </div>
      <div className="space-y-2">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Button
            color="secondary"
            variant="text"
            style={{ fontSize: "small", padding: "2px" }}
            onClick={() => fetchCategories()}
          >
            Kategorie główne
          </Button>
          {categoryAncestors.map((category) => (
            <Button
              key={category.id}
              color="primary"
              variant="text"
              style={{ fontSize: "small", padding: "2px" }}
              onClick={() => fetchCategories(category.id)}
            >
              {category.name}
            </Button>
          ))}
        </Breadcrumbs>
        {categories.length === 0 && (
          <p className="mt-12 text-gray-500">Brak kategorii</p>
        )}
        <ul className="space-y-2 p-2">
          {categories.map((category) => (
            <CategoryCard
              key={category.id}
              category={category}
              getCategoriesData={fetchCategories}
              showParent={false}
              inParameters={true}
            />
          ))}
        </ul>
        <Controller
          name="search"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Szukaj parametru"
              variant="outlined"
              size="small"
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          )}
        />
        {!usingSearch && currentCategory ? (
          <p>Wyświetlanie parametrów dla kategorii: {currentCategory.name}</p>
        ) : !usingSearch ? (
          <p>Wyświetlanie parametrów globalnych</p>
        ) : (
          <p>
            Wyświetlono {parameters.length} z {totalParameters} parametrów
          </p>
        )}
        <Button
          variant="outlined"
          color="success"
          size="small"
          endIcon={<AddIcon />}
          style={{ fontSize: "small", padding: "6px" }}
          component={Link}
          to={`add/${currentCategory ? currentCategory.id : ""}`}
        >
          Dodaj parametr dla kategorii:{" "}
          {currentCategory ? currentCategory.name : "globalne"}
        </Button>
        {parameters.length === 0 && (
          <p className="mt-12 text-gray-500">Brak parametrów</p>
        )}
        <ul className="space-y-2 p-2">
          {parameters.map((parameter) => (
            <ParameterCard
              key={parameter.id}
              parameter={parameter}
              showCategory={usingSearch}
              getCategoriesData={fetchCategories}
              setUsingSearch={setUsingSearch}
            />
          ))}
        </ul>
        {usingSearch && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(_, page) => getParametersData(page, query)}
            color="primary"
          />
        )}
      </div>
    </div>
  );
}

export default Parameters;
