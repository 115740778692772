import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, InputLabel } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useProducer } from "../context/ProducerContext";

interface ProducerFormData {
  name: string;
  description?: string;
  image?: File | null;
}

interface ProducerFormProps {
  id?: number;
  name?: string;
  description?: string;
  image?: string;
  inEditMode?: boolean;
}

function ProducerForm({
  id,
  name = "",
  description = "",
  image = "",
  inEditMode = false,
}: ProducerFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<ProducerFormData>({
    defaultValues: {
      name,
      description,
      image: null,
    },
  });

  const { createProducer, editProducer } = useProducer();
  const navigate = useNavigate();

  useEffect(() => {
    if (image) {
      setValue("image", null);
    }
  }, [image, setValue]);

  const onSubmit = async (data: ProducerFormData) => {
    const toastId = toast.loading(
      inEditMode ? "Edytowanie..." : "Dodawanie...",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      }
    );

    try {
      const formData = new FormData();
      formData.append("name", data.name);
      if (data.description) formData.append("description", data.description);
      if (data.image) formData.append("image", data.image);

      if (inEditMode && id) {
        await editProducer(id, data.name, data.description, data.image);
        toast.update(toastId, {
          render: "Producent edytowany!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        await createProducer(data.name, data.description, data.image);
        toast.update(toastId, {
          render: "Producent dodany!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }

      navigate("/admin/producers");
    } catch (error: Error | any) {
      console.error("Failed to process producer", error);
      toast.update(toastId, {
        render: inEditMode
          ? "Nie udało się edytować producenta"
          : "Nie udało się dodać producenta",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col space-y-2 items-stretch w-full"
    >
      <Controller
        name="name"
        control={control}
        defaultValue={name}
        rules={{ required: "Nazwa jest wymagana" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Nazwa"
            error={!!errors.name}
            helperText={errors.name ? String(errors.name.message) : ""}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        defaultValue={description}
        render={({ field }) => (
          <TextField {...field} label="Opis" multiline rows={4} />
        )}
      />
      <Controller
        name="image"
        control={control}
        defaultValue={null}
        render={({ field }) => (
          <>
            <InputLabel htmlFor="image-input">Dodaj zdjęcie</InputLabel>
            <input
              id="image-input"
              type="file"
              accept="image/*"
              onChange={(e) => field.onChange(e.target.files?.[0] || null)}
              style={{ marginTop: "8px" }}
            />
            {image && !field.value && (
              <img
                src={image}
                alt="Current"
                style={{ maxWidth: "100px", marginTop: "8px" }}
              />
            )}
            {errors.image && (
              <p style={{ color: "red" }}>{String(errors.image.message)}</p>
            )}
          </>
        )}
      />
      <Button type="submit" variant="contained" color="primary">
        {inEditMode ? "Zaktualizuj producenta" : "Utwórz producenta"}
      </Button>
    </form>
  );
}

export default ProducerForm;
