import React, { useEffect, useState } from "react";
import { Breadcrumbs, Button, Pagination, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useCategory } from "../../../context/CategoryContext";
import CategoryCard from "../../../components/CategoryCard";
import useDebounce from "../../../hooks/useDebounce";
import { Category } from "../../../models/Category";

function Categories() {
  const { control } = useForm();
  const [query, setQuery] = React.useState("");

  const [categories, setCategories] = useState<Category[]>([]);
  const [categoryAncestors, setCategoryAncestors] = useState<Category[]>([]);
  const [totalCategories, setTotalCategories] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [usingSearch, setUsingSearch] = useState<boolean>(false);

  const { getCategories, searchCategories } = useCategory();

  const debouncedQuery = useDebounce(query, 500);

  const getSearchCategoriesData = async (page: number, query: string) => {
    const response = await searchCategories(page, 10, query);
    setCategories(response.categories);
    setTotalCategories(response.totalCategories);
    setTotalPages(response.totalPages);
    setCurrentPage(response.currentPage);
    setUsingSearch(true);
  };

  const getCategoriesData = async (categoryId?: number) => {
    const response = await getCategories(categoryId!);
    setCategories(response.categories);
    setCategoryAncestors(response.ancestors);
    setUsingSearch(false);
  };

  useEffect(() => {
    if (debouncedQuery !== "") {
      getSearchCategoriesData(currentPage, debouncedQuery);
    }
  }, [debouncedQuery]);

  useEffect(() => {
    getCategoriesData();
  }, []);

  return (
    <div className="flex flex-col items-stretch space-y-5">
      <div className="flex items-center space-x-2">
        <Button
          variant="outlined"
          color="success"
          size="small"
          endIcon={<AddIcon />}
          style={{ fontSize: "small", padding: "6px" }}
          component={Link}
          to="add"
        >
          Dodaj kategorie główną
        </Button>
      </div>
      <div className="space-y-2">
        <h3 className="text-2xl">Drzewko kategorii</h3>
        <Controller
          name="search"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Szukaj kategorii"
              variant="outlined"
              size="small"
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          )}
        />
        {!usingSearch && (
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Button
              color="secondary"
              variant="text"
              style={{ fontSize: "small", padding: "2px" }}
              onClick={() => getCategoriesData()}
            >
              Kategorie główne
            </Button>
            {categoryAncestors.map((category) => (
              <Button
                key={category.id}
                color="primary"
                variant="text"
                style={{ fontSize: "small", padding: "2px" }}
                onClick={() => getCategoriesData(category.id)}
              >
                {category.name}
              </Button>
            ))}
          </Breadcrumbs>
        )}
        {usingSearch && (
          <p>
            Wyświetlono {categories.length} z {totalCategories} kategorii
          </p>
        )}
        {categories.length === 0 && (
          <p className="mt-12 text-gray-500">Brak kategorii</p>
        )}
        <ul className="space-y-2 p-2">
          {categories.map((category) => (
            <CategoryCard
              key={category.id}
              getCategoriesData={getCategoriesData}
              category={category}
              showParent={usingSearch}
            />
          ))}
        </ul>
        {usingSearch && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(_, page) => getSearchCategoriesData(page, query)}
            color="primary"
          />
        )}
      </div>
    </div>
  );
}

export default Categories;
