import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import "../styles/pages/admin.css";
import Producers from "./admin/producer/Producers";
import { Button, IconButton, Menu } from "@mui/material";
import { CloseOutlined, Logout, MenuBook } from "@mui/icons-material";
import { useAuth } from "../context/AuthContext";
import EditProducer from "./admin/producer/EditProducer";
import AddProducer from "./admin/producer/AddProducer";
import Categories from "./admin/categories/Categories";
import AddCategory from "./admin/categories/AddCategory";
import EditCategory from "./admin/categories/EditCategory";
import Parameters from "./admin/parameters/Parameters";
import AddParameter from "./admin/parameters/AddParameter";
import EditParameter from "./admin/parameters/EditParameter";
import AddProduct from "./admin/products/AddProduct";
import Products from "./admin/products/Products";
import Inquiries from "./admin/inquiries/Inquiries";
import Inquiry from "./admin/inquiries/InquiryPage";
import AddOffer from "./admin/offers/AddOffer";
import Offers from "./admin/offers/Offers";
import EditProduct from "./admin/products/EditProduct";
import Content from "./admin/content/Content";
import { animated, useSpring } from "react-spring";

function Admin() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const width = window.innerWidth;

  const [isSidePanelOpen, setIsSidePanelOpen] = React.useState(
    width > 768 ? true : false
  );

  const slideStyle = useSpring({
    transform: isSidePanelOpen ? "translateX(0%)" : "translateX(-100%)",
    config: { tension: 300, friction: 40 },
  });
  const sidePanelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidePanelRef.current &&
        !sidePanelRef.current.contains(event.target as Node)
      ) {
        setIsSidePanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidePanelOpen]);

  return (
    <div className="grid grid-cols-6 gap-4 py-5">
      <animated.div
        className={`
          ${
            width > 768
              ? "col-span-1"
              : "h-full overflow-y-scroll w-[400px] max-w-[80vw]"
          }`}
        ref={width <= 768 ? sidePanelRef : null}
        style={
          width <= 768
            ? {
                ...slideStyle,
                position: "fixed",
                padding: "1rem",
                left: 0,
                top: 0,
                height: "100vh",
                backgroundColor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                zIndex: 100,
              }
            : {}
        }
      >
        {width <= 768 && (
          <IconButton
            onClick={() => setIsSidePanelOpen(false)}
            style={{ marginBottom: "20px" }}
          >
            <CloseOutlined />
          </IconButton>
        )}
        <div className="flex flex-col">
          <Link
            to=""
            className="p-2 rounded-sm hover:shadow-md hover:drop-shadow-md"
          >
            Panel główny
          </Link>
          <p className="text-xl font-semibold border-b-2 p-2 uppercase">
            Operacje
          </p>
          <Link
            to="inquiries"
            className="p-2 rounded-sm hover:shadow-md hover:drop-shadow-md"
          >
            Zapytania
          </Link>
          <Link
            to="offers"
            className="p-2 rounded-sm hover:shadow-md hover:drop-shadow-md"
          >
            Oferty
          </Link>
          <p className="text-xl font-semibold border-b-2 p-2">Zarządznie</p>
          <Link
            to="producers"
            className="p-2 rounded-sm hover:shadow-md hover:drop-shadow-md"
          >
            Producenci
          </Link>
          <Link
            to="categories"
            className="p-2 rounded-sm hover:shadow-md hover:drop-shadow-md"
          >
            Kategorie
          </Link>
          <Link
            to="parameters"
            className="p-2 rounded-sm hover:shadow-md hover:drop-shadow-md"
          >
            Parametry
          </Link>
          <Link
            to="products"
            className="p-2 rounded-sm hover:shadow-md hover:drop-shadow-md"
          >
            Produkty
          </Link>
          <Button
            variant="contained"
            color="error"
            endIcon={<Logout />}
            style={{
              padding: "5px",
              marginTop: "20px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            Wyloguj
          </Button>
        </div>
      </animated.div>
      <div className={`${width <= 768 ? "col-span-6" : "col-span-5"} p-5`}>
        {width <= 768 && (
          <Button
            variant="text"
            color="primary"
            onClick={() => setIsSidePanelOpen(true)}
            style={{ marginBottom: "20px" }}
            endIcon={<MenuBook />}
          >
            Menu
          </Button>
        )}
        <Routes>
          <Route path="/" element={<Content />} />
          <Route path="/producers" element={<Producers />} />
          <Route path="/producers/add" element={<AddProducer />} />
          <Route path="/producers/edit/:id" element={<EditProducer />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/categories/add/:id?" element={<AddCategory />} />
          <Route path="/categories/edit/:id" element={<EditCategory />} />
          <Route path="/parameters" element={<Parameters />} />
          <Route path="/parameters/add/:id?" element={<AddParameter />} />
          <Route path="/parameters/edit/:id" element={<EditParameter />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/edit/:id" element={<EditProduct />} />
          <Route path="/inquiries" element={<Inquiries />} />
          <Route path="/inquiries/:inquiryId" element={<Inquiry />} />
          <Route path="/offers/" element={<Offers />} />
          <Route path="/offers/add/:inquiryId" element={<AddOffer />} />
        </Routes>
      </div>
    </div>
  );
}

export default Admin;
