import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, InputLabel, Chip } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useProduct } from "../context/ProductContext";
import CategorySelect from "./components/CategorySelect";
import ProducerSelect from "./components/ProducerSelect";
import { useParameter } from "../context/ParameterContext";
import SecondaryImages from "./components/SecondaryImages";
import { Product, Tag } from "../models/Product";
import { Parameter } from "../models/Parameter";
import { Close } from "@mui/icons-material";

interface ProductFormData {
  name: string;
  serialNumber: string;
  categoryId: number;
  producerId: number;
  description?: string;
  price: number;
  primaryImage?: File | null;
  keywords?: string;
  secondaryImages: File[];
  secondaryImagesOrders: number[];
  parameterValues: { id: number; value: string }[];
  tag: Tag | null;
}

interface ProductFormProps {
  product?: Product;
  inEditMode?: boolean;
}

function ProductForm({ product, inEditMode = false }: ProductFormProps) {
  const { createProduct, editProduct } = useProduct();

  const [parameters, setParameters] = useState<Parameter[]>([]);
  const { getParametersForCategory } = useParameter();

  const [categoryId, setCategoryId] = useState<number>(
    product?.categoryId || 0
  );

  useEffect(() => {
    const fetchParameters = async () => {
      const parameters = await getParametersForCategory(categoryId);
      setParameters(parameters);
    };

    fetchParameters();
  }, [categoryId]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ProductFormData>({
    defaultValues: {
      name: product?.name || "",
      serialNumber: product?.serialNumber || "",
      categoryId: product?.categoryId || 0,
      producerId: product?.producerId || 0,
      description: product?.description || "",
      price: product?.price || 0,
      primaryImage: null,
      keywords: product?.keywords || "",
      secondaryImages: [] as File[],
      secondaryImagesOrders:
        product?.secondaryImages?.map((img) => img.order) || [],
      parameterValues: parameters.map((param) => ({
        id: param.id,
        value:
          product?.parameterValues?.find((pv) => pv.parameterId === param.id)
            ?.value || "",
      })),
      tag: product?.tag || Tag.New,
    },
  });

  const navigate = useNavigate();

  const [keywordInput, setKeywordInput] = useState("");
  const [keywordList, setKeywordList] = useState<string[]>(
    product?.keywords ? product.keywords.split(";") : []
  );

  const [changeCategory, setChangeCategory] = useState(false);
  const [changeProducer, setChangeProducer] = useState(false);
  const [changePrimaryImage, setChangePrimaryImage] = useState(false);
  const [removedImages, setRemovedImages] = useState(new Set<number>());

  const handleKeywordKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      if (keywordInput.trim()) {
        setKeywordList([...keywordList, keywordInput.trim()]);
        setKeywordInput("");
      }
    }
  };

  const removeKeyword = (index: number) => {
    setKeywordList(keywordList.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (product?.primaryImage) setValue("primaryImage", null);
  }, [product?.primaryImage, setValue]);

  useEffect(() => {
    setValue(
      "parameterValues",
      parameters.map((param) => ({
        id: param.id,
        value:
          product?.parameterValues?.find((pv) => pv.parameterId === param.id)
            ?.value || "",
      }))
    );
  }, [product?.parameterValues, parameters, setValue]);

  const onSubmit = async (data: ProductFormData) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("serialNumber", data.serialNumber);
    formData.append("categoryId", String(data.categoryId));
    formData.append("producerId", String(data.producerId));
    formData.append("description", data.description || "");
    formData.append("price", String(data.price));
    formData.append("keywords", keywordList.join(";"));
    formData.append("tag", data.tag || "");
    if (data.primaryImage) formData.append("primaryImage", data.primaryImage);
    data.secondaryImages.forEach((img) =>
      formData.append("secondaryImages", img)
    );
    data.secondaryImagesOrders.forEach((order) =>
      formData.append("secondaryImagesOrders", String(order))
    );
    formData.append("parameterValues", JSON.stringify(data.parameterValues));

    const toastId = toast.loading(
      inEditMode ? "Edytowanie..." : "Dodawanie...",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      }
    );

    try {
      if (inEditMode && product?.id) {
        await editProduct(product?.id, Array.from(removedImages), formData);
        toast.update(toastId, {
          render: "Produkt edytowany!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        await createProduct(formData);
        toast.update(toastId, {
          render: "Produkt dodany!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }

      navigate("/admin/products");
    } catch (error: any) {
      console.error("Failed to process product", error);
      toast.update(toastId, {
        render: inEditMode
          ? "Nie udało się edytować produktu"
          : "Nie udało się dodać produktu",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col space-y-2 items-stretch w-full"
    >
      <Controller
        name="name"
        control={control}
        rules={{ required: "Nazwa jest wymagana" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Nazwa"
            error={!!errors.name}
            helperText={errors.name ? String(errors.name.message) : ""}
          />
        )}
      />
      <Controller
        name="serialNumber"
        control={control}
        rules={{ required: "Numer seryjny jest wymagany" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Numer seryjny"
            error={!!errors.serialNumber}
            helperText={
              errors.serialNumber ? String(errors.serialNumber.message) : ""
            }
          />
        )}
      />
      {inEditMode && !changeCategory && (
        <div className="flex items-center space-x-2">
          <p>
            Kategoria:{" "}
            {product?.category
              ? product.category.name
              : "Nie wybrano kategorii"}
          </p>
          <Button
            variant="text"
            size="small"
            style={{ padding: 0, fontSize: "small" }}
            onClick={() => setChangeCategory(true)}
          >
            Zmień kategorię
          </Button>
        </div>
      )}
      {(!inEditMode || changeCategory) && (
        <CategorySelect
          setCategoryId={setCategoryId}
          control={control}
          errors={errors}
        />
      )}
      {inEditMode && !changeProducer && (
        <div className="flex items-center space-x-2">
          <p>
            Kategoria:{" "}
            {product?.producer
              ? product.producer.name
              : "Nie wybrano producenta"}
          </p>
          <Button
            variant="text"
            style={{ padding: 0, fontSize: "small" }}
            size="small"
            onClick={() => setChangeProducer(true)}
          >
            Zmień producenta
          </Button>
        </div>
      )}
      {(!inEditMode || changeProducer) && (
        <ProducerSelect control={control} errors={errors} />
      )}
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="Opis" multiline rows={4} />
        )}
      />
      {/* <QuillEditor /> */}
      <Controller
        name="price"
        control={control}
        rules={{ required: "Cena jest wymagana", min: 0 }}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            label="Cena"
            error={!!errors.price}
            helperText={errors.price ? String(errors.price.message) : ""}
          />
        )}
      />
      <div>
        <TextField
          id="keywords-input"
          value={keywordInput}
          label="Słowa kluczowe"
          onChange={(e) => setKeywordInput(e.target.value)}
          onKeyDown={handleKeywordKeyDown}
          fullWidth
          placeholder="Wpisz słowo kluczowe i naciśnij Enter"
        />
        <div className="keyword-chips" style={{ marginTop: "8px" }}>
          {keywordList.map((keyword, index) => (
            <Chip
              key={index}
              label={keyword}
              onDelete={() => removeKeyword(index)}
              style={{ marginRight: "8px", marginBottom: "8px" }}
            />
          ))}
        </div>
      </div>
      <Controller
        name="tag"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Tag"
            fullWidth
            SelectProps={{ native: true }}
          >
            <option value={undefined}>Brak</option>
            <option value={Tag.New}>Nowy</option>
            <option value={Tag.Sale}>Wyprzedaż</option>
            <option value={Tag.Bestseller}>Bestseller</option>
            <option value={Tag.Recommended}>Polecany</option>
          </TextField>
        )}
      />
      {inEditMode && !changePrimaryImage && (
        <div className="flex items-center space-x-3">
          <img
            src={product?.primaryImage}
            alt="Current"
            style={{ maxWidth: "100px" }}
          />
          <Button
            variant="text"
            size="small"
            color="error"
            endIcon={<Close />}
            style={{ padding: 0, fontSize: "small" }}
            onClick={() => setChangePrimaryImage(true)}
          >
            Usuń główne zdjęcie
          </Button>
        </div>
      )}
      {(!inEditMode || changePrimaryImage) && (
        <Controller
          name="primaryImage"
          control={control}
          render={({ field }) => (
            <>
              <InputLabel htmlFor="primary-image-input">
                Dodaj główne zdjęcie
              </InputLabel>
              <input
                id="primary-image-input"
                type="file"
                accept="image/*"
                name="primaryImage"
                onChange={(e) => field.onChange(e.target.files?.[0] || null)}
                style={{ marginTop: "8px" }}
              />
            </>
          )}
        />
      )}
      <SecondaryImages
        control={control}
        watch={watch}
        setValue={setValue}
        secondaryImages={product?.secondaryImages || []}
        setRemovedImages={setRemovedImages}
      />
      <h3 className="text-lg">Parametry produktu</h3>
      {parameters.length === 0 && (
        <p className="text-gray-500">Brak parametrów dla wybranej kategorii</p>
      )}
      {parameters.map((param, index) => (
        <Controller
          key={param.id}
          name={`parameterValues.${index}.value`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={param.name}
              defaultValue={product?.parameterValues?.[index]?.value || ""}
              fullWidth
              margin="normal"
              error={!!errors.parameterValues?.[index]?.value}
              helperText={
                errors.parameterValues?.[index]?.value && "Wartość wymagana"
              }
              onChange={(e) => {
                field.onChange(e.target.value);
                setValue(`parameterValues.${index}.id`, param.id);
              }}
            />
          )}
        />
      ))}
      <Button type="submit" variant="contained" color="primary">
        {inEditMode ? "Zaktualizuj produkt" : "Utwórz produkt"}
      </Button>
    </form>
  );
}

export default ProductForm;
