import React, { createContext, useContext, ReactNode } from "react";
import axios from "axios";
import { withAuth } from "../middleware/withAuth";
import { Offer } from "../models/Offer";

export interface SendOfferRequestBody {
  inquiryId: number;
  offerDetails: string;
  validUntil: string;
  products: Array<{
    id: number;
    price: number;
    quantity: number;
    removeProductFromOffer?: boolean;
  }>;
}

interface OfferContextType {
  sendOffer: (data: SendOfferRequestBody) => Promise<void>;
  getOffer: (offerId: number) => Promise<Offer>;
  getOffers: (
    limit: number,
    page: number,
    search?: string
  ) => Promise<{
    offers: Offer[];
    totalOffers: number;
    totalPages: number;
  }>;
}

const OfferContext = createContext<OfferContextType | undefined>(undefined);

export const useOffer = () => {
  const context = useContext(OfferContext);
  if (!context) {
    throw new Error("useOffer must be used within an OfferProvider");
  }
  return context;
};

interface OfferProviderProps {
  children: ReactNode;
}

export const OfferProvider: React.FC<OfferProviderProps> = ({ children }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const sendOffer = async (data: SendOfferRequestBody) => {
    try {
      const config = await withAuth({
        method: "post",
        url: `${apiUrl}/api/offers`,
        data,
      });

      await axios(config);
    } catch (error: any) {
      console.error("Failed to send offer", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getOffer = async (offerId: number): Promise<Offer> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/offers/public/${offerId}`,
      };

      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch offer", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getOffers = async (
    limit: number,
    page: number,
    search = ""
  ): Promise<{
    offers: Offer[];
    totalOffers: number;
    totalPages: number;
  }> => {
    try {
      const config = await withAuth({
        method: "get",
        url: `${apiUrl}/api/offers`,
        params: {
          limit,
          page,
          search,
        },
      });

      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch offers", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  return (
    <OfferContext.Provider
      value={{
        sendOffer,
        getOffer,
        getOffers,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};
