import React, { ReactNode } from "react";

import { SearchProvider } from "./SearchContext";
import { ParameterProvider } from "./ParameterContext";
import { ProductProvider } from "./ProductContext";
import { InquiryProvider } from "./InquiryContext";
import { ProducerProvider } from "./ProducerContext";
import { CategoryProvider } from "./CategoryContext";
import { ShoppingListProvider } from "./ShoppingListContext";
import { OfferProvider } from "./OfferContext";
import AuthProvider from "./AuthContext";
import { ContentProvider } from "./ContentContext";

interface CombinedProviderProps {
  children: ReactNode;
}

const CombinedProvider: React.FC<CombinedProviderProps> = ({ children }) => {
  return (
    <AuthProvider>
      <ContentProvider>
        <SearchProvider>
          <ParameterProvider>
            <ProductProvider>
              <InquiryProvider>
                <ProducerProvider>
                  <CategoryProvider>
                    <ShoppingListProvider>
                      <OfferProvider>{children}</OfferProvider>
                    </ShoppingListProvider>
                  </CategoryProvider>
                </ProducerProvider>
              </InquiryProvider>
            </ProductProvider>
          </ParameterProvider>
        </SearchProvider>
      </ContentProvider>
    </AuthProvider>
  );
};

export default CombinedProvider;
