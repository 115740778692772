import { IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProductForm from "../../../forms/ProductForm";

function AddProduct() {
  return (
    <div>
      <IconButton component={Link} to="/admin/products">
        <ArrowBackIcon />
      </IconButton>
      <ProductForm />
    </div>
  );
}

export default AddProduct;
