import React, { createContext, useContext, ReactNode } from "react";
import axios from "axios";
import { Category } from "../models/Category";
import { withAuth } from "../middleware/withAuth";

interface CategoryContextType {
  createCategory: (name: string, parentCategoryId?: number) => Promise<void>;
  editCategory: (id: number, name: string) => Promise<void>;
  getCategories: (id?: number) => Promise<{
    categories: Category[];
    ancestors: Category[];
    currentCategory: Category;
  }>;
  searchCategories: (
    page?: number,
    limit?: number,
    query?: string
  ) => Promise<{
    categories: Category[];
    totalCategories: number;
    totalPages: number;
    currentPage: number;
  }>;
  getCategory: (id: number) => Promise<Category>;
  deleteCategory: (id: number) => Promise<void>;
}

const CategoryContext = createContext<CategoryContextType | undefined>(
  undefined
);

export const useCategory = () => {
  const context = useContext(CategoryContext);
  if (!context) {
    throw new Error("useCategory must be used within a CategoryProvider");
  }
  return context;
};

interface CategoryProviderProps {
  children: ReactNode;
}

export const CategoryProvider: React.FC<CategoryProviderProps> = ({
  children,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const deleteCategory = async (id: number) => {
    try {
      const config = await withAuth({
        method: "delete",
        url: `${apiUrl}/api/Categories/Category/${id}`,
      });
      await axios(config);
    } catch (error: any) {
      console.error("Failed to delete Category", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const searchCategories = async (
    page?: number,
    limit?: number,
    query?: string
  ): Promise<{
    categories: Category[];
    totalCategories: number;
    totalPages: number;
    currentPage: number;
  }> => {
    try {
      const config = await withAuth({
        method: "get",
        url: `${apiUrl}/api/categories/search`,
        params: { page, limit, search: query },
      });
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to search Categories", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getCategories = async (
    id?: number
  ): Promise<{
    categories: Category[];
    ancestors: Category[];
    currentCategory: Category;
  }> => {
    try {
      const config = await {
        method: "get",
        url: `${apiUrl}/api/categories/${id ? `${id}` : ""}`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch Categories", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getCategory = async (id: number): Promise<Category> => {
    try {
      const config = await withAuth({
        method: "get",
        url: `${apiUrl}/api/categories/category/${id}`,
      });
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch Category", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const createCategory = async (name: string, parentCategoryId?: number) => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      console.log(name);
      if (parentCategoryId)
        formData.append("parentCategoryId", parentCategoryId.toString());

      const config = await withAuth({
        method: "post",
        url: `${apiUrl}/api/categories/category`,
        headers: { "Content-Type": "application/json" },
        data: formData,
      });
      await axios(config);
    } catch (error: any) {
      console.error("Failed to create Category", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const editCategory = async (id: number, name: string) => {
    try {
      const formData = new FormData();
      formData.append("name", name);

      const config = await withAuth({
        method: "put",
        url: `${apiUrl}/api/Categories/Category/${id}`,
        headers: { "Content-Type": "application/json" },
        data: formData,
      });
      await axios(config);
    } catch (error: any) {
      console.error("Failed to edit Category", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  return (
    <CategoryContext.Provider
      value={{
        searchCategories,
        createCategory,
        getCategories,
        getCategory,
        deleteCategory,
        editCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
